<div class="container-fluid container-partner">
    <div class="container">

        <div class="row contact">
            <div class="col-12 col-md-4 contact-form">
                <div class="d-block">
                    <div class="box-footer-mobile">
                        <img src="assets/images/logo-footer.png" alt="">
                        <div class="box-info">
                            <span>©2021 2PMarketing. All Rights Reserved.</span>
                            <div>
                                <a href="https://2pmkt.s3-sa-east-1.amazonaws.com/politicas-termos/termo-condicoes.pdf" target="_blank">Termos de uso</a>
                                <a href="#">Política de privacidade</a>
                                <a href="#">Cookies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
