<div class="container">
  <div class="row">
    <div class="col-12 col-lg-4 offset-lg-4">
      <div class="d-flex flex-column justify-content-center">
        <h2>Alteração de senha</h2>

        <div class="mt-4">
            <form [formGroup]="alterarSenhaForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="oldPassword">Senha atual</label>
                  <input type="password" name="oldPassword" formControlName="oldPassword" placeholder="Senha atual" class="form-control" [ngClass]="{ 'is-invalid': submitted && alterarSenhaForm.get('oldPassword').errors }">
                  <div *ngIf="submitted && alterarSenhaForm.get('oldPassword').errors" class="invalid-feedback">
                    <div *ngIf="alterarSenhaForm.get('oldPassword').errors.required">Senha atual é obrigatório</div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="newPassword">Senha nova</label>
                  <input type="password" name="newPassword" formControlName="newPassword" placeholder="Senha nova" class="form-control" [ngClass]="{ 'is-invalid': submitted && alterarSenhaForm.get('newPassword').errors }">
                  <div *ngIf="submitted && alterarSenhaForm.get('newPassword').errors" class="invalid-feedback">
                    <div *ngIf="alterarSenhaForm.get('newPassword').errors.required">Senha nova é obrigatório</div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="confirmPassword">Confirmar senha</label>
                  <input type="password" name="confirmPassword" formControlName="confirmPassword" placeholder="Confirmar senha" class="form-control" [ngClass]="{ 'is-invalid': submitted && alterarSenhaForm.get('confirmPassword').errors }">
                  <div *ngIf="submitted && alterarSenhaForm.get('confirmPassword').errors" class="invalid-feedback">
                    <div *ngIf="alterarSenhaForm.get('confirmPassword').errors.required">Confirmar senha é obrigatório</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="text-center">
                  <a [routerLink]="['/profissional/agencia']" class="btn btn-primary mr-2">Voltar</a>
                  <button class="btn btn-primary">{{buttonText}}</button>
                </div>
              </div>
            </div>

            <!-- <div *ngIf="message" class="alert alert-danger mt-3 mb-0">{{message}}</div> -->
          </form>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="container-fluid alterar-senha-sucess" *ngIf="message">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-4">
              <div class="header-control">
                  <img src="assets/images/logo-footer.png" alt="">
                  <i class="fa fa-close" [routerLink]="['/profissional/agencia']"></i>
              </div>
              <h2>Senha da sua conta alterada com sucesso.</h2>
          </div>
      </div>
  </div>
</div>
