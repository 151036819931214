<div class="container my-5">

  <div class="row" *ngIf="!servicesList">
    <div class="col-12">
      <div class="item mt-5">
        <ngx-skeleton-loader count="5" animation="progress" appearance=""></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="servicesList">
    <div class="col-12">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h2>Alteração de serviços</h2>

        <form [formGroup]="alterarServicoForm" novalidate>
          <div class="col-12 col-md-8 offset-md-2">
            <div class="row mt-4">
              <div class="col-12 col-md-6 mb-5" formArrayName="serviceTypes" *ngFor="let service of servicesList">

                <!-- <pre>{{service | json}}</pre> -->

                <div class="box-oquefaz">
                  <div class="mb-4">
                    <img src="assets/images/img-mkdigital.png" alt="{{service.description}}" *ngIf="service.code == 'marketingdigital'">
                    <img src="assets/images/img-design.png" alt="{{service.description}}" *ngIf="service.code == 'design'">
                    <img src="assets/images/img-web.png" alt="{{service.description}}" *ngIf="service.code == 'web'">
                    <img src="assets/images/img-video.png" alt="{{service.description}}" *ngIf="service.code == 'video'">
                    <img src="assets/images/img-foto.png" alt="{{service.description}}" *ngIf="service.code == 'fotografia'">
                    <span
                      [ngClass]="{'ico-mkdigital': service.code == 'marketingdigital', 'ico-design': service.code == 'design', 'ico-web': service.code == 'web', 'ico-video': service.code == 'video', 'ico-fotografia': service.code == 'fotografia'}"
                      class="ico-mkdigital">{{service.description}}</span>
                  </div>

                  <label class="container-check" [ngClass]="service.code" *ngFor="let serviceType of service.serviceTypes; let index = index">{{serviceType.description}}
                    <input type="checkbox" id="{{serviceType.id}}" [value]="serviceType.id" (change)="onCheckChange($event)" [checked]="serviceType.selecionado">
                    <span class="checkmark"></span>
                  </label>

                </div>

                <div class="mt-3" *ngIf="serviceTypesValidation" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
                  <div>Escolha um tipo de serviço</div>
                </div>

              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <a [routerLink]="['/profissional/agencia']" class="btn btn-voltar mr-2" style="cursor: pointer;">Voltar</a>
            <button class="btn btn-cadastrar" (click)="onSubmit()">{{buttonText}}</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid alterar-servico-sucess" *ngIf="showServicoSuccess">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-4">
              <div class="header-control">
                  <img src="assets/images/logo-footer.png" alt="">
                  <i class="fa fa-close" [routerLink]="['/profissional/agencia']"></i>
              </div>
              <h2>Serviços alterado com sucesso!</h2>
          </div>
      </div>
  </div>
</div>
