import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProfessionalService } from './../../shared/services/professional.service';
import { UserService } from '../../shared/services/user.service';
import { first } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { QuoteStatusEnum } from 'src/app/shared/enums/quote-status.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-agencia',
  templateUrl: './agencia.component.html',
  styleUrls: ['./agencia.component.sass']
})
export class AgenciaComponent implements OnInit {

  listaPedidos: any[] = [];
  listaOrcamentos: any[] = [];
  showBudgets: boolean = false;
  showQuotes: boolean = false;
  message: string;
  loadingOrcamentos: boolean = true;
  loadingPedidos: boolean = true;

  user: {
    name: string,
    email: string,
    cellPhone: string,
    documentNumber: string
    professionalType: number,
    hasEmailDependency: boolean
  };

  closeResult = '';

  constructor(
    private professionalService: ProfessionalService,
    private accountService: AccountService,
    private userService: UserService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

    var prevTime = new Date();  // Jan 1, 2011
    var thisTime = new Date('2021-03-04T22:23:06.83949'); // now
    var diff = thisTime.getTime() - prevTime.getTime();   // now - jan 1

    this.professionalService.fetchProfessional().subscribe(
      res => {
        if (res.data.length == 0) {
          this.showQuotes = false;
          this.loadingOrcamentos = false;
        } else {
          this.loadingOrcamentos = false;
          this.listaPedidos = res.data;
          this.showQuotes = true;
        }
      },
      ({ error }) => {
        console.log(error)
      }
    );

    this.professionalService.fetchBudgets().subscribe(
      res => {
        if (res.data.length == 0) {
          this.showBudgets = false;
          this.loadingPedidos = false;
        } else {
          this.loadingPedidos = false;

          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            element.expireInSeconds = new Date(element.expiredIn).getTime() - prevTime.getTime();
            element.expireInSecondsFinal = element.expireInSeconds / 1000;
          }

          this.listaOrcamentos = res.data;
          this.showBudgets = true;
        }
      },
      ({ error }) => {
        console.log(error)
      }
    );

    this.userService.fetchUser().subscribe(
      res => {
        debugger;
        this.user = res.data.professional;
      },
      ({ error }) => {
        console.log(error)
      }
    );

  }

  // remove o orçamento caso o tempo de envio do mesmo tenha esgotado
  onTimerFinished(e: Event, index, status) {
    // console.log(index)
    // console.log(e)
    console.log(status)
    if (e["action"] == "done") {
      if (status != 2) {
        this.listaOrcamentos.splice(index)
        // this.listaOrcamentos['enviado'][index] = true;
      }
    }

    if (this.listaOrcamentos.length == 0) {
      this.showBudgets = false;
    }

  }

  open(content, id) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      // refaz o pedido ao clicar no botão sim
      this.professionalService.copy(id)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data)
            this.message = data.message;
          },
          error => {
            console.log(error)
            this.message = error;
          });

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fechaConfirmacao() {
    this.message = '';
  }

  // refazerPedido(id) {
  //   this.professionalService.copy(id)
  //       .pipe(first())
  //       .subscribe(
  //           data => {
  //             console.log(data)
  //             this.message = data.message;
  //           },
  //           error => {
  //             console.log(error)
  //             this.message = error;
  //           });
  // }

  accept(id) {
    this.professionalService.accept(id)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.message = data;
          const orcamento = this.listaOrcamentos.find(x => x.quoteId == id);
          if (orcamento) {
            orcamento.status = QuoteStatusEnum.Confirmed;
            orcamento.acceptedIn = Date.now();
          }
        },
        error => {
          console.log(error)
          this.message = error;
        });
  }

  reject(id) {
    this.professionalService.reject(id)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.message = data;

          const orcamento = this.listaOrcamentos.find(x => x.quoteId == id);
          if (orcamento) {
            orcamento.status = QuoteStatusEnum.Rejected;
            orcamento.rejectedIn = Date.now();
          }
        },
        error => {
          console.log(error)
          this.message = error;
        });
  }

  resendEmail() {
    this.accountService
      .resendConfirmation('', this.user.email)
      .subscribe(data => {
        this.alertService.success('E-mail de confirmação reenviado com sucesso!');
      });
  }

}
