import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact-footer',
  templateUrl: './contact-footer.component.html',
  styleUrls: ['./contact-footer.component.sass']
})
export class ContactFooterComponent implements OnInit {
  submitted = false;
  showSuccess: boolean = false;
  message: string;
  contatoForm = new FormGroup({});
  buttonText = "Enviar";

  constructor(
    public router: Router,
    private contactService: ContactService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {

    this.contatoForm = this.formBuilder.group(
      {
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        message: new FormControl('', Validators.required)
      }
    )
  }

  onSubmit() {
    this.submitted = true;
    this.buttonText = "Enviando...";

    if (this.contatoForm.invalid) {
      this.buttonText = "Enviar";
        return;
    }

    this.contactService.sendContact({ ...this.contatoForm.value }).subscribe(
      res => {
        console.log(res)
        this.showSuccess = true;
        this.message = 'Sua mensagem foi enviada com sucesso!'
        this.contatoForm.reset();
      },
      ({ error }) => {
        this.showSuccess = false;
        this.message = error.message;
        this.buttonText = "Enviar";
      }
    );
  }

}
