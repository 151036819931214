import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { User } from '../shared/_models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfessionalService } from './../shared/services/professional.service';
import { UserService } from './../shared/services/user.service';
import { ServiceTypeService } from '../shared/services/serviceType.service';

@Component({
  selector: 'app-alterar-servicos',
  templateUrl: './alterar-servicos.component.html',
  styleUrls: ['./alterar-servicos.component.sass']
})
export class AlterarServicosComponent implements OnInit {

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  message: '';
  showServicoSuccess: boolean = false;
  servicesList: Array<any>;
  serviceTypesUserList: Array<any>;
  alterarServicoForm = new FormGroup({});
  buttonText = "Alterar";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private professionalService: ProfessionalService,
    private userService: UserService,
    private serviceTypeService: ServiceTypeService
  ) { }

  ngOnInit(): void {

    this.alterarServicoForm = this.formBuilder.group(
      {
        // professionalType: new FormControl('', Validators.required),
        serviceTypes: new FormArray([], Validators.required),
      }
    )

    this.serviceTypeService.fetchServices().subscribe(data => {
      this.servicesList = data.data;

      this.professionalService.fetchProfessionalServiceTypes().subscribe(res => {
        this.serviceTypesUserList = res.data;

        const formArray: FormArray = this.alterarServicoForm.get('serviceTypes') as FormArray;

        for (let i = 0; i < this.servicesList.length; i++) {

          for (let o = 0; o < this.servicesList[i].serviceTypes.length; o++) {

            for (let e = 0; e < this.serviceTypesUserList.length; e++) {

              for (let f = 0; f < this.serviceTypesUserList[e].serviceTypes.length; f++) {

                if(this.servicesList[i].serviceTypes[o].id == this.serviceTypesUserList[e].serviceTypes[f].id){
                  this.servicesList[i].serviceTypes[o].selecionado = true;
                  formArray.push(new FormControl(this.servicesList[i].serviceTypes[o].id));
                }

              }

            }

          }

        }

      })

    });

    this.userService.fetchUser().subscribe(res => {

    });

  }

  onCheckChange(event) {
    const formArray: FormArray = this.alterarServicoForm.get('serviceTypes') as FormArray;

    debugger;
    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
    }
    else{
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onSubmit() {

    this.submitted = true;
    this.buttonText = "Carregando...";

    // para se o form estiver inválido
    if (this.alterarServicoForm.invalid) {
      this.buttonText = "Alterar";
        return;
    }

    this.loading = true;

    this.professionalService.sendProfessinalServiceTypes({ ...this.alterarServicoForm.value }).subscribe(
      res => {
        console.log(res)
        this.message = res.message;
        this.showServicoSuccess = true;
      },
      ({ error }) => {
        this.message = error.message;
        console.log(error)
      }
    );

  }


}
