import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lgpd',
  templateUrl: './lgpd.component.html',
  styleUrls: ['./lgpd.component.sass']
})
export class LgpdComponent implements OnInit  {

  // @ViewChild('cookie') el:ElementRef;
  showCookie: boolean = true;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    if(localStorage.getItem('cookie_consent') == 'consent') {
      this.showCookie = false;
    }else{
      localStorage.setItem('cookie_consent', 'non-consent');
      this.showCookie = true;
    }
  }

  consent(){
    localStorage.setItem('cookie_consent', 'consent');
    this.showCookie = false;
  }

}
