<div class="container-fluid bg-login">
    <div class="container container-login">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-8">
                <div class="d-flex justify-content-center align-items-center">
                    <a href="/">
                        <img src="assets/images/logo-header.png" alt="" >
                    </a>
                </div>
                <div class="mt-4">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <input type="text" name="email" formControlName="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && loginForm.get('email').errors }">
                            <div *ngIf="submitted && loginForm.get('email').errors" class="invalid-feedback">
                              <div *ngIf="loginForm.get('email').errors.required">E-mail é obrigatório</div>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <input type="password" name="password" formControlName="password" placeholder="Senha" [ngClass]="{ 'is-invalid': submitted && loginForm.get('password').errors }">
                            <div *ngIf="submitted && loginForm.get('password').errors" class="invalid-feedback">
                              <div *ngIf="loginForm.get('password').errors.required">Senha é obrigatório</div>
                            </div>
                        </div>
                        <a [routerLink]="['/esqueci-senha']" class="mt-2">
                            <small>*Esqueci minha senha</small>
                        </a>
                        <div class="d-flex justify-content-center align-items-center mt-4">
                            <button type="submit" class="btn btn-entrar">{{buttonTextLogin}}</button>
                        </div>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
