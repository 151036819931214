import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from 'ngx-cookieconsent';

import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../_models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  currentUser: User;

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x );
  }

  ngOnInit(): void {
  }


  logout() {
      this.authenticationService.logout();
      window.location.href="/";
  }

}
