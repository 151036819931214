export enum QuoteStatusEnum {
    /// <summary>
    /// Pendente
    /// </summary>
    Pending = 1,

    /// <summary>
    /// Confirmado
    /// </summary>
    Confirmed = 2,

    /// <summary>
    /// Rejeitado
    /// </summary>
    Rejected = 3,

    /// <summary>
    /// Expirado
    /// </summary>
    Expired = 4,

    /// <summary>
    /// Completado
    /// </summary>
    Completed = 5
}