import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  constructor(private api: ApiService) {}

  fetchProfessional() {
    const endpoint = 'Customer/quotes';
    const route = environment.API_URL + endpoint;
    return this.api.get$(route);
  }

  fetchBudgets() {
    const endpoint = 'professional/budgets';
    const route = environment.API_URL + endpoint;
    return this.api.get$(route);
  }

  fetchProfessionalServiceTypes() {
    const endpoint = 'professional/service-types';
    const route = environment.API_URL + endpoint;
    return this.api.get$(route);
  }

  accept(data) {
    const endpoint = 'Quote/'+ data +'/accept';
    const route = environment.API_URL + endpoint;
    return this.api.patch$(route, data);
  }

  reject(data) {
    const endpoint = 'Quote/'+ data +'/reject';
    const route = environment.API_URL + endpoint;
    return this.api.patch$(route, data);
  }

  copy(data) {
    const endpoint = 'Quote/'+ data +'/copy';
    const route = environment.API_URL + endpoint;
    return this.api.post$(route, data);
  }

  sendProfessionalData(data) {
    const endpoint = 'Professional/addresses';
    const route = environment.API_URL + endpoint;
    return this.api.patch$(route, data);
  }

  sendProfessinalServiceTypes(data) {
    const endpoint = 'Professional/service-types';
    const route = environment.API_URL + endpoint;
    return this.api.patch$(route, data);
  }
}
