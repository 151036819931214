import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private api: ApiService) {}

  resendConfirmation(userId: string, email: string) {
    const endpoint = 'Account/resend-confirmation';
    const route = environment.API_URL + endpoint + '?userId=' + userId + '&email=' + email;
    return this.api.get$(route);
  }
}
