import { Component, OnInit } from '@angular/core';
import { ConfirmaEmailService } from '../shared/services/confirma-email.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService } from '../shared/services/account.service';
import { AlertService } from '../shared/services/alert.service';

@Component({
  selector: 'app-confirmaEmail',
  templateUrl: './confirmaEmail.component.html',
  styleUrls: ['./confirmaEmail.component.sass']
})
export class ConfirmaEmailComponent implements OnInit {
  userId: string;
  userCode: string;
  error = '';
  success: boolean = false;

  constructor(
    private confirmaEmailService: ConfirmaEmailService,
    private accountService: AccountService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.userCode = encodeURIComponent(this.route.snapshot.queryParamMap.get('code'));

    this.confirmaEmailService.getUserData(this.userId, this.userCode)
        .pipe(first())
        .subscribe(
            data => {
              console.log(data)
              this.success = true;
            },
            error => {
              // this.error = error;
              this.error = 'Link expirado. Clique no  botão para reenviar um novo e-mail ou entre em contato com o suporte.';
              this.success = false;
              console.log(error)
            });
  }

  resendEmail() {
    this.accountService
      .resendConfirmation(this.userId, '')
      .subscribe(data => {
        this.alertService.success('E-mail de confirmação reenviado com sucesso!');
      });
  }

}
