<div class="container mt-lg-0 my-4">
  <div class="row">
    <div class="col-12 col-lg-6 offset-lg-3">
      <div class="d-flex flex-column justify-content-center">
        <h2>Alteração de cadastro</h2>

        <div class="mt-4">
          <form [formGroup]="alterarCadastroForm" (ngSubmit)="onSubmit()" novalidate>

            <div class="row">
              <div class="col-12 col-lg-8">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input type="text" name="name" formControlName="name" placeholder="Nome" class="form-control">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="cellPhone">Telefone</label>
                  <input type="text" name="cellPhone" formControlName="cellPhone" placeholder="Telefone" class="form-control" mask="(00) 00000-0000">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-9">
                <div class="form-group">
                  <label for="street">Endereço</label>
                  <input type="text" name="street" formControlName="street" placeholder="Endereço" class="form-control">
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <div class="form-group">
                  <label for="number">Número</label>
                  <input type="text" name="number" formControlName="number" placeholder="Número" class="form-control">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <label for="complement">Complemento</label>
                  <input type="text" name="complement" formControlName="complement" placeholder="Complemento" class="form-control">
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <label for="neighborhood">Bairro</label>
                  <input type="text" name="neighborhood" formControlName="neighborhood" placeholder="Bairro" class="form-control">
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="city">Cidade</label>
                  <input type="text" name="city" formControlName="city" placeholder="Cidade" class="form-control">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="state">Estado</label>
                  <input type="text" name="state" formControlName="state" placeholder="Estado" class="form-control">
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="zipCode">Cep</label>
                  <input type="text" name="zipCode" formControlName="zipCode" placeholder="Cep" class="form-control" mask="00000-000">
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="text-center">
                  <a [routerLink]="['/profissional/agencia']" class="btn btn-primary mr-2">Voltar</a>
                  <button class="btn btn-primary">{{buttonText}}</button>
                </div>
              </div>
            </div>

            <div class="mt-4" *ngIf="message" style="color: #dc3545;">
                {{message}}
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="container-fluid alterar-cadastro-sucess" *ngIf="showCadastroSuccess">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-4">
              <div class="header-control">
                  <img src="assets/images/logo-footer.png" alt="">
                  <i class="fa fa-close" [routerLink]="['/profissional/agencia']"></i>
              </div>
              <h2>Cadastro alterado com sucesso!</h2>
          </div>
      </div>
  </div>
</div>
