<div class="container">

    <div class="row">
        <div class="col-12">
          <div class="row" *ngIf="!user">
            <div class="col-12">
              <div class="item mt-5">
                <ngx-skeleton-loader count="5" animation="progress" appearance=""></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="user-info mt-5" *ngIf="user">
              <h2>{{user.name}}</h2>
              <span>Número de Cliente: {{user.documentNumber}}</span>
              <span>{{user.email}}</span>
              <span>{{user.cellPhone | mask: '(00) 00000-0000'}}</span>
              <span *ngIf="user.professionalPlan">Quantidade de Leads disponíveis: {{user.professionalPlan.availabilityQuote}}</span>
              <a [routerLink]="['/alterar-cadastro']" class="btn btn-sm btn-primary mt-4 mr-2">Alterar cadastro</a>
              <a [routerLink]="['/alterar-senha']" class="btn btn-sm btn-primary mt-4 mr-2">Alterar senha</a>
              <a [routerLink]="['/alterar-servicos']" class="btn btn-sm btn-primary mt-4">Alterar serviços</a>
          </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-11">
            <div class="historico-pedidos mt-5">

                <div *ngIf="user">
                  <h2 *ngIf="user.professionalType == 2">Agência</h2>
                  <h2 *ngIf="user.professionalType == 3">Freelance</h2>
                  <h2 *ngIf="user.professionalType == 1">Agência / Freelance</h2>
                </div>

                <div id="accordion" class="mt-4">

                    <div class="row" *ngIf="loadingOrcamentos">
                      <div class="col-12">
                        <div class="item mt-5">
                          <ngx-skeleton-loader count="5" animation="progress" appearance=""></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>

                    <!-- histórico de orçamentos -->
                    <div class="mt-5 mb-5" *ngIf="!showBudgets && !loadingOrcamentos && !user.hasEmailDependency">
                      <h4 style="color: #8f8f8f;"><i class="fa fa-clock-o"></i> Não existem orçamentos disponíveis no momento.</h4>
                    </div>

                    <!-- histórico de orçamentos -->
                    <div class="mt-5 mb-5" *ngIf="user.hasEmailDependency">
                      <h4 style="color: #8f8f8f;"><i class="fa fa-clock-o"></i> Conta não confirmada - Favor Verificar E-mail de Confirmação.</h4>
                      <h4 style="color: #8f8f8f;"><i class="fa fa-clock-o"></i> Você não receberá solicitações de orçamentos enquanto não confirmar a sua conta.</h4>
                      <button type="button" class="btn btn-sm btn-primary mt-4" (click)="resendEmail()">
                        <i class="fa fa-envelope mr-3" aria-hidden="true"></i>
                        Reenviar E-mail
                      </button>
                    </div>

                    <div class="card" *ngIf="showBudgets">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <!-- <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> -->
                                <button class="btn btn-link collapsed">
                                    Histórico de Orçamentos
                                </button>
                            </h5>
                        </div>
                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body">
                                <div id="accordionInside">
                                    <div class="card" *ngFor="let orcamento of listaOrcamentos; let i = index">
                                        <div class="card-header" id="{{orcamento.quoteId}}">
                                            <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapse{{orcamento.quoteId}}" aria-expanded="true" aria-controls="collapse{{orcamento.quoteId}}">
                                                {{orcamento.customerName}}
                                            </button>
                                            </h5>
                                        </div>
                                        <div id="collapse{{orcamento.quoteId}}" class="collapse show" aria-labelledby="{{orcamento.quoteId}}" data-parent="#accordionInside">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-8">
                                                        <p>{{orcamento.customerEmail}}</p>
                                                        <p>{{orcamento.customerPhone | mask: '(00) 00000-0000'}}</p>

                                                        <ul class="my-3">
                                                          <li *ngFor="let service of orcamento.serviceTypes">
                                                            {{service.description}}
                                                          </li>
                                                        </ul>

                                                        <p><strong>Informações Adicionais</strong></p>
                                                        <span>{{orcamento.description}}</span>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p><strong>Data do Pedido</strong></p>
                                                        <p>{{orcamento.orderDate | date:"dd/MM/yyyy"}}</p>

                                                        <div class="mt-3" *ngIf="orcamento.rejectedIn && !orcamento.acceptedIn">
                                                          <p><strong>Negado em</strong></p>
                                                          <p>{{orcamento.rejectedIn | date:"dd/MM/yyyy"}}</p>
                                                        </div>

                                                        <div class="mt-3" *ngIf="orcamento.acceptedIn">
                                                          <p><strong>Aceito em</strong></p>
                                                          <p>{{orcamento.acceptedIn | date:"dd/MM/yyyy"}}</p>
                                                        </div>
                                                        

                                                        <div class="mt-3" *ngIf="orcamento.status === 1">
                                                            <p class="mt-4 mb-2"><strong>O orçamento já foi enviado?</strong></p>
                                                            <a class="btn btn-confirma" style="cursor:pointer;" (click)="accept(orcamento.quoteId)">Sim</a>
                                                            <a class="btn btn-confirma ml-3" style="cursor:pointer;" (click)="reject(orcamento.quoteId)">Não</a>

                                                            <div style="font-size:30px; font-weight: 700;">
                                                              <countdown [config]="{leftTime: orcamento.expireInSecondsFinal, format: 'HH:mm:ss' }" (event)="onTimerFinished($event, i, orcamento.status)"></countdown>
                                                            </div>

                                                            <div *ngIf="message" style="margin-top: 0.25rem;color: #dc3545;">
                                                              {{message}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="card">
                                        <div class="card-header" id="headingTwoInside">
                                            <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwoInside" aria-expanded="true" aria-controls="collapseTwoInside">
                                                #1234 Pedro da Silva
                                            </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwoInside" class="collapse" aria-labelledby="headingTwoInside" data-parent="#accordionInside">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-8">
                                                        <p>pedro@gmail.com</p>
                                                        <p>11 91234-4567</p>

                                                        <p class="my-3"><strong>Design</strong></p>
                                                        <p><strong>Informações Adicionais</strong></p>
                                                        <span>Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e vem sendo utilizado desde o século XVI, quando um impressor desconhecido pegou uma bandeja de tipos e os embaralhou para fazer um livro de modelos de tipos.</span>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <p><strong>Data do Pedido</strong></p>
                                                        <p>25/09/2020</p>

                                                        <p class="mt-4"><strong>O orçamento já foi enviado?</strong></p>
                                                        <div class="mt-3">
                                                            <a href="" class="btn btn-confirma disabled">Sim</a>
                                                            <a href="" class="btn btn-confirma ml-3">Não</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- histórico de pedidos -->
                    <!-- <div class="mt-5" *ngIf="!showQuotes">
                      <h4 style="color: #dc3545;"><i class="fa fa-exclamation"></i> Não existem pedidos.</h4>
                    </div> -->

                    <div class="row" *ngIf="loadingPedidos">
                      <div class="col-12">
                        <div class="item mt-5">
                          <ngx-skeleton-loader count="5" animation="progress" appearance=""></ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>

                    <div class="card mt-5" *ngIf="showQuotes">
                      <div class="card-header" id="headingOne">
                          <h5 class="mb-0">
                              <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> -->
                              <button class="btn btn-link">
                                  Histórico de Pedidos
                              </button>
                          </h5>
                      </div>
                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                          <div class="card-body">
                              <div class="table-responsive">
                                  <table class="table">
                                      <tr *ngFor="let pedido of listaPedidos">
                                          <td>
                                              <p>Design</p>
                                              <span>{{pedido.professionalEmail}}</span>
                                          </td>
                                          <td>
                                              <p>Data do Pedido</p>
                                              <span>{{pedido.orderDate | date:'dd/MM/yyyy'}}</span>
                                          </td>
                                          <td>
                                              <p>Demanda</p>
                                              <span>
                                                  {{pedido.description}}
                                              </span>
                                          </td>
                                          <td>
                                              <a class="btn btn-refazer-pedido" (click)="open(content, pedido.quoteId)">
                                                Refazer Pedido
                                              </a>
                                              <!-- <a class="btn btn-refazer-pedido" (click)="refazerPedido(pedido.quoteId)">
                                                Refazer Pedido
                                              </a> -->
                                          </td>
                                      </tr>
                                  </table>
                                  <div *ngIf="message" style="margin-top: 0.25rem;color: #dc3545;">
                                    {{message}}
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Refazer pedido</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Deseja refazer o pedido?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Sim</button>
  </div>
</ng-template>


<div class="container-fluid refazer-pedido-sucess" *ngIf="message">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-4">
              <div class="header-control">
                  <img src="assets/images/logo-footer.png" alt="">
                  <i class="fa fa-close" (click)="fechaConfirmacao()"></i>
              </div>
              <h2>Item salvo com sucesso!</h2>
          </div>
      </div>
  </div>
</div>
