<div class="container-fluid bg-cadastro">

	<div class="progress-bar">
		<div class="row">
			<div class="col-4">
				<div class="etapa1-ok" *ngIf="etapa1"></div>
			</div>
			<div class="col-4">
				<div class="etapa2-ok" *ngIf="etapa2"></div>
			</div>
			<div class="col-4">
				<div class="etapa3-ok" *ngIf="etapa3"></div>
			</div>
		</div>
	</div>

	<div class="container my-5">
		<div class="row">
			<div class="col-12 col-md-6 offset-md-3">
        <a href="/">
          <img src="assets/images/logo-header.png" alt="" style="cursor: pointer;">
        </a>
				
			</div>
		</div>

		<form [formGroup]="cadastroForm" novalidate>

      <div class="row etapa1" *ngIf="etapa1">
        <div class="col-12 col-md-4 offset-md-4">
          <div class="mt-5">
          <h2>Letsgo!</h2>
          <hr class="my-3">
          <p>Junte-se a maior plataforma de
            <br>marketing do Brasil</p>
          </div>
          <div class="mt-4">
            <div class="form-group">
              <label for="">Seu E-mail</label>
              <input type="email" name="email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('email').errors }">

              <div *ngIf="submitted && cadastroForm.get('email').errors" class="invalid-feedback">
                <div *ngIf="cadastroForm.get('email').errors.required">Email é obrigatório</div>
                <div *ngIf="cadastroForm.get('email').errors.email">Digite um e-mail válido</div>
              </div>

            </div>
            <div class="form-group">
              <label for="">Voce é...</label>
              <div class="row">
                <div class="col-6 agencia">
                  <div [ngClass]="{'selected': userTypeButton == 2}" class="box-voce d-flex flex-column justify-content-center align-items-center" (click)="userType('agencia')">
                    <img src="assets/images/img-agencia-gray.png" alt="">
                    <span class="mt-2">agência</span>
                    <input type="radio" name="professionalType" id="professionalType" [value]="2" formControlName="professionalType" required style="display:none;">
                  </div>
                </div>
                <div class="col-6 freelance">
                  <div [ngClass]="{'selected': userTypeButton == 3}" class="box-voce d-flex flex-column justify-content-center align-items-center" (click)="userType('freelancer')">
                    <img src="assets/images/img-freela-gray.png" alt="">
                    <span class="mt-2">freelancer</span>
                    <input type="radio" name="professionalType" id="professionalType" [value]="1" formControlName="professionalType" required style="display:none;">
                  </div>
                </div>

                <div *ngIf="submitted && cadastroForm.get('professionalType').errors" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
                  <div *ngIf="cadastroForm.get('professionalType').errors.required">Escolha um tipo de profissional</div>
                </div>
              </div>

            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-proximo mt-3" (click)="step2('etapa2')">Próximo</button>
            </div>
          </div>

          <div class="paginas my-5">
            <h3>1 de 3</h3>
          </div>
        </div>
      </div>

      <div class="row etapa2" *ngIf="etapa2">
        <div class="col-12 col-md-4 offset-md-4">
          <div class="mt5">
            <h2>O que<br>você faz?</h2>
            <hr class="my-3">
            <p>Marque abaixo o que você faz<br>Para enviarmos orçamentos que você<br>Possa atender.</p>
          </div>
        </div>

        <div class="col-12 col-md-8 offset-md-2">
          <div class="row mt-4">
            <div class="col-12 col-md-6 mb-5" formArrayName="serviceTypes" *ngFor="let service of servicesList">

              <div class="box-oquefaz">
                <div class="mb-4">
                  <img src="assets/images/img-mkdigital.png" alt="{{service.description}}" *ngIf="service.code == 'marketingdigital'">
                  <img src="assets/images/img-design.png" alt="{{service.description}}" *ngIf="service.code == 'design'">
                  <img src="assets/images/img-web.png" alt="{{service.description}}" *ngIf="service.code == 'web'">
                  <img src="assets/images/img-video.png" alt="{{service.description}}" *ngIf="service.code == 'video'">
                  <img src="assets/images/img-foto.png" alt="{{service.description}}" *ngIf="service.code == 'fotografia'">
                  <span
                    [ngClass]="{'ico-mkdigital': service.code == 'marketingdigital', 'ico-design': service.code == 'design', 'ico-web': service.code == 'web', 'ico-video': service.code == 'video', 'ico-fotografia': service.code == 'fotografia'}"
                    class="ico-mkdigital">{{service.description}}</span>
                </div>

                <label class="container-check" [ngClass]="service.code" *ngFor="let serviceType of service.serviceTypes; let index = index">{{serviceType.description}}
                  <input type="checkbox" id="{{serviceType.id}}" [value]="serviceType.id" (change)="onCheckChange($event)">
                  <span class="checkmark"></span>
                </label>

              </div>

              <div class="mt-3" *ngIf="serviceTypesValidation" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
                <div>Escolha um tipo de serviço</div>
              </div>

            </div>
          </div>

          <div class="mt-lg-3 d-flex justify-content-between">
            <button class="btn btn-voltar" (click)="stepBack1()">Voltar</button>
            <button class="btn btn-proximo" (click)="step3('etapa3')">Próximo</button>
          </div>

          <div class="paginas my-5">
            <h3>2 de 3</h3>
          </div>
        </div>
      </div>

      <div class="row etapa3" *ngIf="etapa3">
        <div class="col-12 col-md-4 offset-md-4">
          <div class="mt-5">
            <h2>Estamos acabando</h2>
            <hr class="my-3">
            <p>Marque abaixo o que você faz<br>
              Para enviarmos orçamentos que você<br>
              Possa atender.</p>
            </div>
            <div class="mt-4">

              <div class="form-group">
                <label for="name">Seu Nome</label>
                <input type="text" name="nome" formControlName="name" maxlength="50" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('name').errors }" />
                <div *ngIf="submitted && cadastroForm.get('name').errors" class="invalid-feedback">
                  <div *ngIf="cadastroForm.get('name').errors.required">Nome é obrigatório</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-12 col-md-6">
                  <label for="cellphone">Celular</label>
                  <input type="text" name="celular" formControlName="cellphone" mask="(00) 00000-0000" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('cellphone').errors }">

                  <div *ngIf="submitted && cadastroForm.get('cellphone').errors" class="invalid-feedback">
                    <div *ngIf="cadastroForm.get('cellphone').errors.required">Celular é obrigatório</div>
                  </div>
                </div>

                <div *ngIf="userTypeButton == 2" class="col-12 col-md-6">
                  <label for="documentNumber">CNPJ</label>
                  <input type="text" name="documento" formControlName="documentNumber" mask="00.000.000/0000-00" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('documentNumber').errors }">

                  <div *ngIf="submitted && cadastroForm.get('documentNumber').errors" class="invalid-feedback">
                    <div *ngIf="cadastroForm.get('documentNumber').errors.required">CNPJ é obrigatório</div>
                  </div>
                </div>
                <div *ngIf="userTypeButton == 3" class="col-12 col-md-6">
                  <label for="documentNumber">CPF</label>
                  <input type="text" name="documento" formControlName="documentNumber" mask="000.000.000-00" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('documentNumber').errors }">

                  <div *ngIf="submitted && cadastroForm.get('documentNumber').errors" class="invalid-feedback">
                    <div *ngIf="cadastroForm.get('documentNumber').errors.required">CPF é obrigatório</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12 col-md-6">
                  <label for="password">Criar Senha</label>
                  <input type="password" name="senha" formControlName="password" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('password').errors }" />

                  <div *ngIf="submitted && cadastroForm.get('password').errors" class="invalid-feedback">
                    <div *ngIf="cadastroForm.get('password').errors.required">Senha é obrigatória</div>
                    <div *ngIf="cadastroForm.get('password').errors.minlength">A senha tem que ter pelo menos 6 caracteres</div>
                  </div>

                </div>
                <div class="col-12 col-md-6">
                  <label for="passwordConfirm">Confirmar Senha</label>
                  <input type="password" name="comfirmaSenha" formControlName="passwordConfirm" [ngClass]="{ 'is-invalid': submitted && cadastroForm.get('passwordConfirm').errors }" />

                  <div *ngIf="submitted && cadastroForm.get('passwordConfirm').errors" class="invalid-feedback">
                    <div *ngIf="cadastroForm.get('passwordConfirm').errors.required">Confirmar Senha é obrigatória</div>
                    <div *ngIf="cadastroForm.get('passwordConfirm').errors.mustMatch">As senhas devem ser iguais</div>
                  </div>
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-between">
                <!-- <button class="btn btn-cadastrar mt-3" [disabled]="cadastroForm.get('name').invalid || cadastroForm.get('cellphone').invalid || cadastroForm.get('documentNumber').invalid || cadastroForm.get('password').invalid || cadastroForm.get('passwordConfirm').invalid" (click)="onSubmit()">Cadastrar</button> -->
                <button class="btn btn-voltar" (click)="stepBack2()">Voltar</button>
                <button class="btn btn-cadastrar" [disabled]="isLoading" (click)="onSubmit()">Cadastrar</button>
              </div>
            </div>

            <div class="paginas my-5">
              <h3>3 de 3</h3>
            </div>
          </div>
      </div>

		</form>

	</div>
</div>

<div class="container-fluid cadastro-sucess" *ngIf="showCadastroSuccess">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4">
        <div class="header-control">
          <img src="assets/images/logo-footer.png" alt="">
          <i class="fa fa-close" (click)="fechaCadastroSuccess()"></i>
          <!-- <i class="fa fa-close" [routerLink]="['/']"></i> -->
        </div>
        <h2>Pronto!<br>Cadastro Realizado!</h2>
        <hr class="my-4">
        <p>Fique atento!</p>
        <p>Enviaremos os orçamentos via</p>
        <p>E-mail no endereço cadastrado!</p>
      </div>
    </div>
  </div>
</div>
