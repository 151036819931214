import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../shared/_models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfessionalService } from './../shared/services/professional.service';
import { UserService } from './../shared/services/user.service';

@Component({
  selector: 'app-alterar-cadastro',
  templateUrl: './alterar-cadastro.component.html',
  styleUrls: ['./alterar-cadastro.component.sass']
})
export class AlterarCadastroComponent implements OnInit {

  alterarCadastroForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  message: '';
  showCadastroSuccess: boolean = false;
  buttonText = "Alterar";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private professionalService: ProfessionalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.userService.fetchUser().subscribe(res => {
      this.alterarCadastroForm.get('name').setValue(res.data.professional.name)
      this.alterarCadastroForm.get('cellPhone').setValue(res.data.professional.cellPhone)
      this.alterarCadastroForm.get('street').setValue(res.data.professional.addresses[0].street)
      this.alterarCadastroForm.get('number').setValue(res.data.professional.addresses[0].number)
      this.alterarCadastroForm.get('complement').setValue(res.data.professional.addresses[0].complement)
      this.alterarCadastroForm.get('zipCode').setValue(res.data.professional.addresses[0].zipCode)
      this.alterarCadastroForm.get('neighborhood').setValue(res.data.professional.addresses[0].neighborhood)
      this.alterarCadastroForm.get('city').setValue(res.data.professional.addresses[0].city)
      this.alterarCadastroForm.get('state').setValue(res.data.professional.addresses[0].state)
    });

    this.alterarCadastroForm = this.formBuilder.group({
      name: [''],
      cellPhone: [''],
      street: [''],
      number: [''],
      complement: [''],
      zipCode: [''],
      neighborhood: [''],
      city: [''],
      state: [''],
    });
  }

  onSubmit() {
    this.submitted = true;
    this.buttonText = "Carregando...";

    // para se o form estiver inválido
    if (this.alterarCadastroForm.invalid) {
      this.buttonText = "Alterar";
        return;
    }

    this.loading = true;

    this.professionalService.sendProfessionalData({ ...this.alterarCadastroForm.value }).subscribe(
      res => {
        console.log(res)
        this.message = res.message;
        this.showCadastroSuccess = true;
      },
      ({ error }) => {
        this.message = error.message;
        console.log(error)
      }
    );

  }

}
