import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  constructor(private api: ApiService) {}

  fetchServices() {
    const endpoint = 'Service';
    const route = environment.API_URL + endpoint;
    return this.api.get$(route);
  }
}
