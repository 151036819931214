<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="user-info mt-5">
                <h2>{{clienteInfo.name}}</h2>
                <span>Número de Cliente: {{clienteInfo.id}}</span>
                <span>{{clienteInfo.email}}</span>
                <span>{{clienteInfo.telefone}}</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-11">
            <div class="historico-pedidos mt-5">
                <h2>Histórico de Pedidos</h2>

                <div class="table-responsive">
                    <table class="table">
                        <tr *ngFor="let pedidos of PedidosCliente">
                            <td>
                                <p>Design</p>
                                <span>{{pedidos.email}}</span>
                            </td>
                            <td>
                                <p>Data do Pedido</p>
                                <span>{{pedidos.createDate}}</span>
                            </td>
                            <td>
                                <p>Demanda</p>
                                <span>
                                    {{pedidos.demanda}}
                                </span>
                            </td>
                            <td>
                                <a class="btn btn-refazer-pedido" (click)="refazerPedido(pedidos.id)">Refazer Pedido</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
