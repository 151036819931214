import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { PasswordService } from './../shared/services/password.service';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.sass']
})
export class EsqueciSenhaComponent implements OnInit {

  esqueciSenhaForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  sucess=false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private passwordService: PasswordService
  ) {}

  ngOnInit(): void {
    this.esqueciSenhaForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'profissional/agencia';
  }

  // conveniencia para acessar de forma mais fácil os form fields
  get f() { return this.esqueciSenhaForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.error = '';
    this.sucess = false;
    console.log(this.esqueciSenhaForm)
    // para se o form estiver inválido
    if (this.esqueciSenhaForm.invalid) {
        return;
    }

    this.passwordService.sendEsqueciSenhaData(this.esqueciSenhaForm.value)
        .pipe(first())
        .subscribe(
            data => {
              console.log(data)
              this.sucess = true;
            },
            error => {
              this.error = error;
              console.log(error)
            });

  }

}
