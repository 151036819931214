<div class="container-fluid">
    <div class="bg-topo d-none d-sm-block"></div>
</div>

<div class="container">
    <div class="row seja-parceiro px-4 px-lg-0">
        <div class="col-12 col-md-6">
            <h2>Seja um parceiro</h2>
            <hr class="mt-5 mb-3">
            <p>Junte-se a maior plataforma<br>de marketing services do Brasil</p>
        </div>
        <div class="col-12 col-md-6 d-none d-sm-block">
            <img src="assets/images/img-about-us.png" alt="" class="img-fluid">
        </div>
    </div>
</div>

<div class="container-fluid bg-beneficios">
    <div class="container">
        <div class="row cadastro">
            <div class="col-12 col-md-6 d-none d-sm-block">
                <img src="assets/images/img-orcamento.png" alt="" class="img-fluid">
            </div>
            <div class="col-12 col-md-6">
                <h3>A 2P vai te ajudar a alavancar suas vendas, aumentando seu leque de clientes, e conseguindo o tão sonhado fluxo que gera estabilidade.</h3>
                <hr class="mt-5 mb-3">
                <p *ngIf="!currentUser">Cadastre-se e receba<br>orçamento gratuitamente*</p>
                <a *ngIf="!currentUser" href="/cadastro" class="btn btn-cadastro mt-4">Cadastre-se</a>
            </div>
        </div>

        <div class="row beneficios">
            <div class="col-12 col-md-4">
                <h2>Benefícios</h2>
                <hr class="mt-4 mb-3">
                <p>Com a 2P, você tem muitas possibilidades de aumentar a sua cartela de clientes,
                expandindo o leque para todo o Brasil, sem precisar fazer  o trabalho de ir atrás dos clientes.</p>
                <h3 class="mt-5">Deixa que a gente te conecta com eles!</h3>
                <a *ngIf="!currentUser" href="/cadastro" class="btn btn-cadastro mt-5 d-none d-sm-block">Cadastre-se</a>
            </div>
            <div class="col-12 col-md-8">
                <div class="box-image">
                    <img src="assets/images/img-beneficios.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-12 mb-5 mb-lg-0">
                <div class="container-video">
                    <iframe class="video" width="654" height="361" src="https://www.youtube.com/embed/AqMCDYbhVHU?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            
            <div class="col-12 col-md-4">
                <p class="number">R$32,9bi</p>
                <p class="description">São Paulo é líder de investimento em comunicação e marketing</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="number"><i class="fa fa-arrow-up"></i> 56%</p>
                <p class="description">Crescer o investimento em mídia e entretenimento no Brasil</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="number">+48%</p>
                <p class="description">Das agências terceirizam produção de conteúdo.</p>
            </div>
        </div>


        <div class="row planosContainer mt-5" *ngIf="listaPlanos">

          <div class="col-12 mb-5">
            <h5>Planos</h5>
            <h1>Alcance novos clientes<br>com mais facilidade</h1>
          </div>

          <div class="col-12 col-lg-4">
            <div class="planosBox mb-lg-0 mb-4">
              <span class="titulo">trial</span>
              <span class="valor"><span>30dias</span></span>
              <ul class="mt-5">
                <li><i class="fa fa-check-circle"></i> Histórico de solicitação</li>
                <li><i class="fa fa-check-circle"></i> Até 2 novos leads</li>
                <li><i class="fa fa-check-circle"></i> 20 dias Free*</li>
                <li><i class="fa fa-check-circle"></i> Restrição geográfica</li>
              </ul>
              <div class="mt5">
                <button *ngIf="!currentUser" [routerLink]="['/cadastro']" class="btn btn-escolherPlano" >
                  Cadastre-se
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4 tipoFreelance">
            <div class="planosBox selected mb-lg-0 mb-4">
              <span class="titulo">freelancer</span>
              <span class="valor">R$ <span>49,90</span> /mês</span>
              <ul class="mt-5">
                <li><i class="fa fa-check-circle"></i> Mínimo de 4 novos leads/mês</li>
                <li><i class="fa fa-check-circle"></i> Suporte Personalizado</li>
                <li><i class="fa fa-check-circle"></i> 20 dias Free*</li>
                <li><i class="fa fa-check-circle"></i> Sem Restrição geográfica</li>

              </ul>
              <div class="text-center">
                <small>Permanência mínima de 3 meses de assinatura</small>
              </div>
              <div class="mt5">
                <button class="btn btn-escolherPlano">
                  Em breve
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4 tipoAgencia">
            <div class="planosBox selected mb-lg-0 mb-4">
              <span class="titulo">agência</span>
              <span class="valor">R$ <span>99,90</span> /mês</span>
              <ul class="mt-5">
                <li><i class="fa fa-check-circle"></i> Mínimo de 4 novos leads/mês</li>
                <li><i class="fa fa-check-circle"></i> Leads Segmentados por faturamento</li>
                <li><i class="fa fa-check-circle"></i> Suporte Personalizado</li>
                <li> <i class="fa fa-check-circle"></i> Sem Restrição geográfica</li>
              </ul>
              <div class="text-center">
                <small>Permanência mínima de 3 meses de assinatura</small>
              </div>
              <div class="mt5">
                <button class="btn btn-escolherPlano" >
                  Em breve
                </button>
              </div>
            </div>
          </div>

        </div>
    </div>
</div>

<div class="container-fluid footer-partner">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-2">
                <div class="box-logo">
                    <img src="assets/images/logo-footer-partner.png" alt="">
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div>
                    <h2>Por que<br>a 2pmkt?</h2>
                    <hr class="mt-4 mb-3">
                    <p>A 2P foi desenvolvido para atender todas as demandas na área da comunicação,
                        criando um elo entre cliente, agência e freelancer, possibilitando que,
                        em poucos cliques, o cliente possa solicitar um orçamento e obter em menos de 24h.
                        3 diferentes orçamentos,  a agência consiga gerenciar e aumentar seu leque de clientes,
                        e o freelancer possa conseguir o tão sonhado fluxo que gere certa estabilidade,
                        sem ter que necessariamente ir atrás do cliente. É a ponte entre o necessitado e o provedor.</p>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="d-flex align-items-center flex-column">
                    <a *ngIf="!currentUser" href="/cadastro" class="btn btn-cadastro">Cadastre-se</a>
                    <hr class="mb-5">
                    <h3>Siga-nos nas<br>redes sociais</h3>

                    <div class="mt-5 social-logos">
                        <a href="https://www.instagram.com/2pmkt_/" target="_blank">
                            <img src="assets/images/logo-instagram.png" alt="">
                        </a>
                        <a href="https://www.facebook.com/2PMKT.marketingservice" target="_blank">
                            <img src="assets/images/logo-facebook.png" alt="">
                        </a>
                        <a href="https://www.linkedin.com/company/2pmkt/" target="_blank">
                            <img src="assets/images/logo-linkedin.png" alt="">
                        </a>
                    </div>

                    <div class="info d-flex justify-content-between mt-5">
                        <img src="assets/images/mini-logo-partner.png" alt="">
                        <div>
                            <span class="mb-2">@2021 2P Marketing. All Rights Reserved.</span>
                            <a href="https://2pmkt.s3-sa-east-1.amazonaws.com/politicas-termos/termo-condicoes.pdf" target="_blank">Termos de Uso</a>
                            <a href="">Política de privacidade</a>
                            <a href="">Cookies</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
