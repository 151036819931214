<div class="container">

    <div class="background d-none d-sm-block" style="background: url(assets/images/img-orcamento.png) no-repeat right top;"></div>
    <div class="row need-know">
        <div class="col-12">
            <h1>Até 3 orçamentos<br>Grátis em 24h</h1>
            <hr>
        </div>
        <div class="col-12 start">
            <p>Para começar precisamos saber:</p>
            <p class="selected" *ngIf="selectedService">{{selectedService}} <i class="fa fa-check" aria-hidden="true"></i></p>
            <p class="selected">São Paulo <i class="fa fa-check" aria-hidden="true"></i></p>
        </div>
    </div>

    <div class="row services mt-4">
      <form [formGroup]="orcamentoForm" novalidate style="width:100%;">

        <div class="col-12 mb-3">
            <hr>
            <h2>Escolha o serviço</h2>
        </div>

        <div class="item mt-5 d-flex justify-content-center" *ngIf="!servicesList">
          <ngx-skeleton-loader count="1" appearance="circle"
          [theme]="{
            'border-radius': '20px',
            height: '70px',
            width: '360px',
            border: '1px solid white'
          }"></ngx-skeleton-loader>
        </div>

        <div class="row" *ngIf="servicesList">
          <div class="col-12 col-lg-4 offset-lg-4 mt-4 mt-lg-0" *ngFor="let service of servicesList; let i = index" [ngClass]="{'offset-md-2': i == 3, 'mb-lg-3 mb-0': i == 0}">
            <div class="white-box" style="border-bottom-left-radius: 0;
                                          border-bottom-right-radius: 0;
                                          border-bottom:0;">
              <label>
                <img src="assets/images/img-mkdigital-gray.png" alt="{{service.description}}" *ngIf="service.code == 'marketingdigital'">
                <img src="assets/images/img-design-gray.png" alt="{{service.description}}" *ngIf="service.code == 'design'">
                <img src="assets/images/img-web-gray.png" alt="{{service.description}}" *ngIf="service.code == 'web'">
                <img src="assets/images/img-video-gray.png" alt="{{service.description}}" *ngIf="service.code == 'video'">
                <img src="assets/images/img-foto-gray.png" alt="{{service.description}}" *ngIf="service.code == 'fotografia'">
                <span>{{service.description}}</span>
                <input type="radio" name="serviceId" id="serviceId" [value]="service.id" formControlName="serviceId" (change)="onItemChange(service.id)" [(ngModel)]="service.id" [checked]="true">
              </label>
            </div>
            <div style="border: solid 1px #DDDDDD;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        border-top: 0;
                        ">
              <div class="white-box-service-type ml-3" *ngFor="let serviceType of service.serviceTypes; let index = index">
                <label class="container-check" [ngClass]="service.code" *ngIf="service.id == serviceIdPai">{{serviceType.description}}
                  <input type="checkbox" id="{{serviceType.id}}" [value]="serviceType.id" (change)="onCheckChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-12 text-center" *ngIf="submitted && orcamentoForm.get('serviceId').errors" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
            <div *ngIf="orcamentoForm.get('serviceId').errors.required">É preciso escolher um tipo de servico</div>
          </div>
        </div>

        <div class="col-12 mb-3 mt-4">
            <hr>
            <h3>Quero receber orçamentos de:</h3>
        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <div class="white-box">
              <label>
                <img src="assets/images/img-agencia-gray.png" alt="">
                <span>agência</span>
                <input type="radio" name="quoteProfessionalType" id="quoteProfessionalType" [value]="2" formControlName="quoteProfessionalType" required>
              </label>
            </div>
          </div>

          <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="white-box">
                <label>
                  <img src="assets/images/img-freela-gray.png" alt="">
                  <span>freela</span>
                  <input type="radio" name="quoteProfessionalType" id="quoteProfessionalType" [value]="3" formControlName="quoteProfessionalType" required>
                </label>
              </div>
          </div>

          <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="white-box">
                <label>
                  <img src="assets/images/img-ambos-gray.png" alt="">
                  <span>ambos</span>
                  <input type="radio" name="quoteProfessionalType" id="quoteProfessionalType" [value]="1" formControlName="quoteProfessionalType" required>
                </label>
              </div>
          </div>

          <div class="col-12 text-center" *ngIf="submitted && orcamentoForm.get('quoteProfessionalType').errors" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
            <div *ngIf="orcamentoForm.get('quoteProfessionalType').errors.required">É preciso escolher um tipo de orçamento</div>
          </div>

        </div>


        <div class="col-12 mb-3 mt-4">
            <hr>
            <h3>Agora pra finalizar:</h3>
        </div>

        <div class="col-12 col-md-4 offset-md-4 mt-4 contact-form">
          <div formGroupName="customer">
            <div class="form-group">
              <label for="name">Seu Nome</label>
              <input type="text" name="nome" formControlName="name" maxlength="50" [ngClass]="{ 'is-invalid': submitted && orcamentoForm.get('customer.name').errors }"/>
              <div *ngIf="submitted && orcamentoForm.get('customer.name').errors" class="invalid-feedback">
                <div *ngIf="orcamentoForm.get('customer.name').errors.required">Nome é obrigatório</div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Seu E-mail</label>
              <input type="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && orcamentoForm.get('customer.email').errors }">

              <div *ngIf="submitted && orcamentoForm.get('customer.email').errors" class="invalid-feedback">
                <div *ngIf="orcamentoForm.get('customer.email').errors.required">Email é obrigatório</div>
                <div *ngIf="orcamentoForm.get('customer.email').errors.email">Digite um e-mail válido</div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Seu Telefone</label>
              <input type="cellPhone" name="cellPhone" formControlName="cellPhone" mask="(00) 00000-0000" [ngClass]="{ 'is-invalid': submitted && orcamentoForm.get('customer.cellPhone').errors }">

              <div *ngIf="submitted && orcamentoForm.get('customer.cellPhone').errors" class="invalid-feedback">
                <div *ngIf="orcamentoForm.get('customer.cellPhone').errors.required">Telefone é obrigatório</div>
              </div>
            </div>
          </div>

          <div class="form-group">
              <label for="">Informações adicionais</label>
              <textarea name="infoAdicional" formControlName="description" cols="30" rows="10" [ngClass]="{ 'is-invalid': submitted && orcamentoForm.get('description').errors }" placeholder="Descreva mais detalhes do que você precisa"></textarea>
              <div *ngIf="submitted && orcamentoForm.get('description').errors" class="invalid-feedback">
                <div *ngIf="orcamentoForm.get('description').errors.required">Mensagem é obrigatório</div>
              </div>
          </div>

          <div *ngIf="!showOrcamentoSuccess" style="width: 100%;
              margin-top: 0.25rem;
              font-size: 80%;
              color: #dc3545;">
                {{message}}
          </div>

          <div class="control-button mt-4">
              <a class="btn btn-enviar" (click)="onSubmit()">Solicitar Orçamento</a>
          </div>
        </div>

      </form>

    </div>

</div>

<app-contact-footer></app-contact-footer>

<div class="container-fluid orcamento-sucess" *ngIf="showOrcamentoSuccess">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4">
                <div class="header-control">
                    <img src="assets/images/logo-footer.png" alt="">
                    <!-- <i class="fa fa-close" (click)="fechaOrcamentoSuccess()"></i> -->
                    <i class="fa fa-close" [routerLink]="['/']"></i>
                </div>
                <h2>Orçamento solicitado com sucesso!</h2>
                <hr class="my-4">
                <p>Fique atento!</p>
                <p>Eviaremos os orçamentos via</p>
                <p>E-mail no endereço cadastrado!</p>
                <div class="button-control">
                    <a [routerLink]="['/']" class="btn btn-outro-pedido">Fazer outro pedido</a>
                </div>
            </div>
        </div>
    </div>
</div>
