<div class="container-fluid cookies" id="cookie" *ngIf="showCookie">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-10">
        <p>A 2PMKT utiliza cookies e outras tecnologias semelhantes para melhorar a sua experiência,
          de acordo com a nossa <a href="">Política de Privacidade</a> e, ao continuar navegando, você concorda
          com estas condições.
        </p>
      </div>
      <div class="col-12 col-lg-2">
        <a class="btn btn-primary btn-block" (click)="consent()">Prosseguir</a>
      </div>
    </div>
  </div>
</div>
