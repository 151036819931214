import { Component, OnInit } from '@angular/core';
import { OrcamentoService } from '../shared/services/orcamento.service';
import { ServiceTypeService } from '../shared/services/serviceType.service';
import { UserService } from '../shared/services/user.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../shared/_models/user';

@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html',
  styleUrls: ['./orcamento.component.sass']
})
export class OrcamentoComponent implements OnInit {

  showOrcamentoSuccess: boolean = false;
  orcamentoForm = new FormGroup({});
  servicesList: Array<any>;
  submitted = false;
  message: string;
  selectedService: string;
  serviceIdPai: string;
  user: [] = [];

  constructor(
    public orcamentoService: OrcamentoService,
    private serviceTypeService: ServiceTypeService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    window.scroll(0,0);

    // pega o id do serviço escolhido no dropdown caso o usuário veio da home
    this.selectedService = this.route.snapshot.queryParamMap.get('service');

    this.orcamentoForm = this.formBuilder.group(
      {
        customer: this.formBuilder.group({
          name: new FormControl('', Validators.required),
          email: new FormControl('', Validators.required),
          cellPhone: new FormControl('', Validators.required),
        }),
        quoteProfessionalType: new FormControl('', Validators.required),
        serviceTypes: new FormArray([], Validators.required),
        // serviceId: new FormArray([], Validators.required),
        serviceId: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        city: new FormControl('São Paulo'),
        state: new FormControl('SP'),
        latitude: new FormControl('-23.5499978'),
        longitude: new FormControl('-46.6333308')
      }
    )

    this.serviceTypeService.fetchServices().subscribe(data => {
      this.servicesList = data.data;

      this.servicesList = data.data.filter(element => {
        // this.serviceIdPai = this.selectedService;
        if(this.selectedService == 'Fotografia') this.orcamentoForm.get('serviceId').setValue('8fb36c23-f997-4c53-bc2c-92708cd8225e');
        if(this.selectedService == 'Marketing Digital') this.orcamentoForm.get('serviceId').setValue('9965e6a2-1fa3-48d1-8ea5-9784b927e47a');
        if(this.selectedService == 'Vídeo') this.orcamentoForm.get('serviceId').setValue('9e746f67-54e8-41ef-ad0a-e73abb594b89');
        if(this.selectedService == 'Design') this.orcamentoForm.get('serviceId').setValue('a0a3a93d-c4dc-4f29-8b83-e2cb73eb9629');
        if(this.selectedService == 'Web') this.orcamentoForm.get('serviceId').setValue('e1b3bf2f-a9a1-4af7-9167-af70259c6bc5');
        return element.description == this.selectedService;
      })

      this.serviceIdPai = this.servicesList[0].id
    });

    if (localStorage.getItem('currentUser') ){
      this.userService.fetchUser().subscribe(res => {
        this.user = res.data.professional.name;
        this.orcamentoForm.get('customer').get('name').setValue(res.data.professional.name)
        this.orcamentoForm.get('customer').get('email').setValue(res.data.professional.email)
        this.orcamentoForm.get('customer').get('cellPhone').setValue(res.data.professional.cellPhone)
      });
    }

  }

  fechaOrcamentoSuccess() {
    this.showOrcamentoSuccess = false;
  }

  // Só será usado quando poder escolher mais de um serviço
  // onServiceChange(event) {
  //   const formArray: FormArray = this.orcamentoForm.get('serviceId') as FormArray;

  //   if(event.target.checked){
  //     formArray.push(new FormControl(event.target.value));
  //   }
  //   else{
  //     let i: number = 0;

  //     formArray.controls.forEach((ctrl: FormControl) => {
  //       if(ctrl.value == event.target.value) {
  //         formArray.removeAt(i);
  //         return;
  //       }
  //       i++;
  //     });
  //   }
  // }

  onItemChange(id) {
    this.serviceIdPai = id;
  }

  onCheckChange(event) {

    const formArray: FormArray = this.orcamentoForm.get('serviceTypes') as FormArray;

    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
    }
    else{
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }

  onSubmit() {
    this.submitted = true;

    if (this.orcamentoForm.invalid) {
        return;
    }

    this.orcamentoService.sendOrcamento({ ...this.orcamentoForm.value }).subscribe(
      res => {
        this.message = res.message;
        this.showOrcamentoSuccess = true;
      },
      ({ error }) => {
        this.showOrcamentoSuccess = false;
        this.message = error.message;
      }
    );
  }

}
