import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/_helpers/auth.guard';

import { HomeComponent } from './home/home.component';
import { OrcamentoComponent } from './orcamento/orcamento.component';
import { PartnerComponent } from './partner/partner.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { LoginComponent } from './login/login.component';
import { ClienteComponent } from './cliente/cliente.component';
import { PedidosComponent } from './cliente/pedidos/pedidos.component';
import { AgenciaComponent } from './profissional/agencia/agencia.component';
import { PlanosComponent } from './planos/planos.component';
import { ConfirmaEmailComponent } from './email/confirmaEmail.component';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import { AlterarCadastroComponent } from './alterar-cadastro/alterar-cadastro.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { AlterarServicosComponent } from './alterar-servicos/alterar-servicos.component';
import { BreveComponent } from './breve/breve.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'breve', component: BreveComponent },
  { path: 'orcamento', component: OrcamentoComponent },
  { path: 'parceiro', component: PartnerComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cliente', component: ClienteComponent },
  { path: 'cliente/pedidos', component: PedidosComponent },
  { path: 'profissional/agencia', component: AgenciaComponent, canActivate: [AuthGuard] },
  { path: 'planos', component: PlanosComponent, canActivate: [AuthGuard] },
  { path: 'confirmaEmail', component: ConfirmaEmailComponent },
  { path: 'esqueci-senha', component: EsqueciSenhaComponent },
  { path: 'alterar-cadastro', component: AlterarCadastroComponent, canActivate: [AuthGuard] },
  { path: 'alterar-senha', component: AlterarSenhaComponent, canActivate: [AuthGuard] },
  { path: 'alterar-servicos', component: AlterarServicosComponent, canActivate: [AuthGuard] }

  // otherwise redirect to home
  // { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
