<div class="container-fluid">
  <div style="overflow: hidden;
  height: 674px;
  width: 625px;
  top: 0;
  right: 0;
  position: absolute;">
    <div class="bg-orcamento"></div>
  </div>

<div class="container-fluid">
  <div style="overflow: hidden;
  height: 575px;
  width: 726px;
  top: 14px;
  left: -70px;
  position: absolute;">
    <div class="bg-orcamento-esquerda"></div>
  </div>

</div>
<div class="container">
  <div class="row planos mt-5">
    <div class="col-12 col-md-6 offset-md-3">
        <h5>Planos</h5>
        <h1>Alcance novos clientes com mais facilidade</h1>
        <hr>
    </div>
    <!-- <div class="col-12 start" [ngClass]="{'tipoAgencia': professionalType == 2, 'tipoFreelance': professionalType == 1}">
        <p>Divulgue seu negócio no Brasil inteiro.</p>
        <div class="planosButton mt-4">
          <div class="planosAgencia" (click)="alteraTipo(2)" [ngClass]="{'selected': professionalType == 2}">
            <img src="../assets/images/img-agencia-gray.png" alt="agência" class="mr-2">
            <span>agência</span>
          </div>
          <div class="planosFreelance" (click)="alteraTipo(1)" [ngClass]="{'selected': professionalType == 1}">
            <img src="../assets/images/img-freela-gray.png" alt="freelance" class="mr-2">
            <span>freelance</span>
          </div>
        </div>
    </div> -->
  </div>

  <div class="row" *ngIf="!listaPlanos || listaPlanos.length == 0">
    <div class="col-12">
      <div class="item mt-5">
        <ngx-skeleton-loader count="3" appearance="circle"
        [theme]="{
          'border-radius': '20px',
          height: '600px',
          width: '360px',
          border: '1px solid white'
        }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="row planosContainer mt-5" *ngIf="listaPlanos" [ngClass]="{'tipoAgencia': professionalType == 2, 'tipoFreelance': professionalType == 1}">

    <div class="col-12 col-lg-4">
      <div class="planosBox mb-lg-0 mb-4">
        <span class="titulo">trial</span>
        <span class="valor"><span>30dias</span></span>
        <ul class="mt-5">
          <li><i class="fa fa-check-circle"></i> Histórico de solicitação</li>
          <li><i class="fa fa-check-circle"></i> Até 2 novos leads</li>
          <li><i class="fa fa-check-circle"></i> 20 dias Free*</li>
          <li><i class="fa fa-check-circle"></i> Restrição geográfica</li>
        </ul>
        <div class="mt5">
          <button class="btn btn-escolherPlano" *ngIf="currentUser">
            Plano Atual
          </button>
          <button class="btn btn-escolherPlano" *ngIf="!currentUser">
            Cadastre-se
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 tipoFreelance">
      <div class="planosBox selected mb-lg-0 mb-4">
        <span class="titulo">freelancer</span>
        <span class="valor">R$ <span>49,90</span> /mês</span>
        <ul class="mt-5">
          <li><i class="fa fa-check-circle"></i> Mínimo de 4 novos leads/mês</li>
          <li><i class="fa fa-check-circle"></i> Suporte Personalizado</li>
          <li><i class="fa fa-check-circle"></i> 20 dias Free*</li>
          <li><i class="fa fa-check-circle"></i> Sem Restrição geográfica</li>

        </ul>
        <div class="text-center">
          <small>Permanência mínima de 3 meses de assinatura</small>
        </div>
        <div class="mt5">
          <button class="btn btn-escolherPlano">
            Em breve
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 tipoAgencia">
      <div class="planosBox selected mb-lg-0 mb-4">
        <span class="titulo">agência</span>
        <span class="valor">R$ <span>99,90</span> /mês</span>
        <ul class="mt-5">
          <li><i class="fa fa-check-circle"></i> Mínimo de 4 novos leads/mês</li>
          <li><i class="fa fa-check-circle"></i> Leads Segmentados por faturamento</li>
          <li><i class="fa fa-check-circle"></i> Suporte Personalizado</li>
          <li> <i class="fa fa-check-circle"></i> Sem Restrição geográfica</li>
        </ul>
        <div class="text-center">
          <small>Permanência mínima de 3 meses de assinatura</small>
        </div>
        <div class="mt5">
          <button class="btn btn-escolherPlano" >
            Em breve
          </button>
        </div>
      </div>
    </div>

  </div>

  <!-- <div class="row planosContainer mt-5" *ngIf="listaPlanos" [ngClass]="{'tipoAgencia': professionalType == 2, 'tipoFreelance': professionalType == 1}">

    <div class="col-12 col-lg-4" *ngFor="let plano of listaPlanos">
      <div class="planosBox mb-lg-0 mb-4" [ngClass]="{'selected': tipoPlano == plano.description}">
        <span class="titulo">{{plano.description}}</span>
        <span class="valor">R$ <span>{{plano.price | currency }}</span></span>

        <ul class="mt-5">
          <li *ngFor="let item of plano.planItems">
            <i class="fa fa-check-circle"></i> {{item.description}}
          </li>
        </ul>

        <div class="mt5">
          <button *ngIf="plano.isFree == true" class="btn btn-escolherPlano" (click)="alteraTipoPlano(plano.description, plano.id)">
            Escolher Plano
          </button>
          <button *ngIf="plano.isFree == false" class="btn btn-escolherPlano">
            Em Breve
          </button>
        </div>
      </div>
    </div>

  </div> -->
</div>

<app-contact-footer></app-contact-footer>
