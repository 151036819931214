import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from './../shared/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  buttonTextLogin = "Entrar";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redireciona a home se estiver logado
    if (this.authenticationService.currentUserValue) {
        this.router.navigate(['profissional/agencia']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'profissional/agencia';

    // document.querySelector(".btn-entrar").innerHTML = 'TESTE';
  }

  // conveniencia para acessar de forma mais fácil os form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.buttonTextLogin = 'Carregando...';
    // para se o form estiver inválido
    if (this.loginForm.invalid) {
      this.buttonTextLogin = 'Entrar';
        return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {
                this.router.navigate([this.returnUrl]);
            },
            error => {
                this.error = error;
                this.loading = false;
                this.buttonTextLogin = 'Entrar';
            });
  }

}
