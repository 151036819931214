import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrcamentoService {

  constructor(private api: ApiService) {}

  sendOrcamento(data) {
    const endpoint = 'quote';
    const route = environment.API_URL + endpoint;
    return this.api.post$(route, data);
  }
}
