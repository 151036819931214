import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ContactService } from './../shared/services/contact.service';

@Component({
  selector: 'app-breve',
  templateUrl: './breve.component.html',
  styleUrls: ['./breve.component.sass']
})
export class BreveComponent implements OnInit {

  breveForm = new FormGroup({});
  submitted = false;
  showCadastroSuccess: boolean = false;
  buttonText = "Avise-me";

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService) { }

  ngOnInit(): void {

    window.location.href="/";

    this.breveForm = this.formBuilder.group(
      {
        name: new FormControl('Lead'),
        email: new FormControl('', [Validators.required, Validators.email]),
        message: new FormControl('Lead Lançamento')
      }
    )
  }

  fechaCadastroSuccess() {
    this.showCadastroSuccess = false;
  }

  onSubmit() {
    this.submitted = true;
    this.buttonText = "Carregando...";

    if (this.breveForm.invalid) {
      this.buttonText = "Avise-me";
        return;
    }

    this.contactService.sendContact({ ...this.breveForm.value }).subscribe(
      res => {
        this.showCadastroSuccess = true;
        this.breveForm.get('email').setValue('');
        this.breveForm.controls['email'].setErrors(null);
        this.buttonText = "Avise-me";
      },
      ({ error }) => {
        this.showCadastroSuccess = false;
        this.buttonText = "Avise-me";
      }
    );
  }

}
