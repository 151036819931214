<div class="container-fluid">
  <div class="container-bg">
    <div class="bg-breve"></div>
    <div class="bg-image">
      <!-- <img src="/assets/images/bg-embreve.jpg" alt=""> -->
    </div>
  </div>

  <div class="container breve-page mb-5">
    <div class="row">
      <div class="col-12">
        <hr class="mt-4">
      </div>

      <div class="col-12 col-lg-8 offset-lg-2">
        <p class="text-center mt-2" style="font-size: 16px;color: #72778F;">O MKT 2P foi desenvolvido para atender todas as demandas na área da comunicação,
          criando um elo entre cliente, agência e freelancer, possibilitando que, em poucos cliques, o cliente
          possa solicitar um orçamento e obter em menos de 24h.
        </p>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 mt-3">
        <form [formGroup]="breveForm" novalidate>
          <div class="form-group">
            <input type="text" name="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && breveForm.get('email').errors }" placeholder="seu-email@mail.com">
            <div *ngIf="submitted && breveForm.get('email').errors" class="invalid-feedback">
              <div *ngIf="breveForm.get('email').errors.required">E-mail é obrigatório</div>
              <div *ngIf="breveForm.get('email').errors?.email">E-mail inválido</div>
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn btn-aviseme" (click)="onSubmit()">{{buttonText}}</button>
          </div>
        </form>

        <div class="text-center mt-5">
          <p style="font-size: 18px;color: #72778F;">
            <strong>Siga-nos nas redes sociais</strong>
          </p>

          <div class="mt-2 social-logos">
            <a href="https://www.instagram.com/2pmkt_/" target="_blank">
                <img src="assets/images/logo-instagram-cinza.png" alt="">
            </a>
            <a href="https://www.facebook.com/2PMKT.marketingservice" target="_blank">
                <img src="assets/images/logo-facebook-cinza.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company/2pmkt/" target="_blank">
                <img src="assets/images/logo-linkedin-cinza.png" alt="">
            </a>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>

<div class="container-fluid cadastro-sucess" *ngIf="showCadastroSuccess">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4">
        <div class="header-control">
          <img src="assets/images/logo-footer.png" alt="">
          <i class="fa fa-close" (click)="fechaCadastroSuccess()"></i>
        </div>
        <h2>Pronto!</h2>
        <hr class="my-4">
        <p>Fique atento!</p>
        <p>Iremos te avisar.</p>
      </div>
    </div>
  </div>
</div>
