import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private api: ApiService) {}

  sendContact(data) {
    const endpoint = 'Contact/Email';
    const route = environment.API_URL + endpoint;
    return this.api.post$(route, data);
  }
}
