<div class="container">

    <div class="row need-know mt-5">
        <div class="col-12 col-md-6 offset-md-3">
            <h1>Aqui você encontra a solução para seu job, 2p!</h1>
            {{text}}
            <hr>
        </div>
        <div class="col-12 start">
          <form class="d-flex flex-column justify-content-center" [formGroup]="homeForm" (submit)="onSubmit()" novalidate>
            <p>Para começar precisamos saber:</p>
            <select name="services" idservices class="mt-2 round" formControlName="services">
                <option value="">Selecione o que você precisa</option>
                <option value="Marketing Digital">Marketing Digital</option>
                <option value="Design">Design</option>
                <option value="Web">Web</option>
                <option value="Vídeo">Vídeo</option>
                <option value="Fotografia">Fotografia</option>
            </select>

            <div class="col-12 text-center" *ngIf="submitted && homeForm.get('services').errors" style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #dc3545;">
              <div *ngIf="homeForm.get('services').errors.required">É preciso escolher um tipo de servico</div>
            </div>

            <!-- <button [routerLink]="['/orcamento']" [queryParams]="{service: serviceOptions}" class="btn btn-continuar mt-5" [disabled]="!serviceOptions">Continuar</button> -->
            <button class="btn btn-continuar mt-5">Continuar</button>
          </form>
        </div>
    </div>

    <div class="row d-block d-sm-none mt-5">
        <div class="col-12">
            <img src="assets/images/img-about-us.png" alt="" class="img-fluid">
        </div>
    </div>

    <div class="item mt-5" *ngIf="!servicesList">
      <ngx-skeleton-loader count="5" appearance="circle"
      [theme]="{
        'border-radius': '20px',
        height: '100px',
        width: '340px',
        border: '1px solid white'
      }"></ngx-skeleton-loader>
    </div>

    <div class="row services mt-4" (clickOutside)="someHandler()" *ngIf="servicesList">
        <div class="col-12 mb-3">
            <hr>
            <h2>Serviços</h2>
        </div>

        <div class="col-12 col-md-4 mb-2" *ngFor="let service of servicesList; let i = index" [ngClass]="{'offset-md-2': i == 3}">
          <div class="white-box" (click)="onItemChange(service.id)">
              <img src="assets/images/img-mkdigital.png" alt="{{service.description}}" *ngIf="service.code == 'marketingdigital'">
              <img src="assets/images/img-design.png" alt="{{service.description}}" *ngIf="service.code == 'design'">
              <img src="assets/images/img-web.png" alt="{{service.description}}" *ngIf="service.code == 'web'">
              <img src="assets/images/img-video.png" alt="{{service.description}}" *ngIf="service.code == 'video'">
              <img src="assets/images/img-foto.png" alt="{{service.description}}" *ngIf="service.code == 'fotografia'">

              <span [ngClass]="{'mkdigital': service.code == 'marketingdigital', 'design': service.code == 'design', 'web': service.code == 'web', 'video': service.code == 'video', 'fotografia': service.code == 'fotografia'}">
                {{service.description}}
              </span>

              <div class="popup-box" *ngIf="service.id == serviceIdPai">
                  <ul>
                      <li *ngFor="let serviceType of service.serviceTypes; let i = index">
                        <i class="fa fa-check ico-mkdigital" aria-hidden="true" *ngIf="service.code == 'marketingdigital'"></i>
                        <i class="fa fa-check ico-design" aria-hidden="true" *ngIf="service.code == 'design'"></i>
                        <i class="fa fa-check ico-web" aria-hidden="true" *ngIf="service.code == 'web'"></i>
                        <i class="fa fa-check ico-video" aria-hidden="true" *ngIf="service.code == 'video'"></i>
                        <i class="fa fa-check ico-fotografia" aria-hidden="true" *ngIf="service.code == 'fotografia'"></i>
                        {{serviceType.description}}
                      </li>
                  </ul>
              </div>
          </div>
        </div>

        <div class="col-12 d-block d-sm-none d-flex align-items-center justify-content-center">
            <a class="btn btn-cadastro mt-5">
                Cadastre-se
            </a>
        </div>
    </div>

    <div class="row about-us mt-5">


        <div class="col-12 image-container d-none d-sm-block">
            <img src="assets/images/img-about-us.png" alt="" class="img-fluid">
        </div>

        <div class="col-3 d-none d-sm-block">
            <div class="faca-pedido">
                <span>
                    1.<br>
                    Faça seu<br>
                    pedido
                </span>
            </div>
        </div>
        <div class="col-3 d-none d-sm-block">
            <div class="orcamento-24">
                <span>
                    2.<br>
                    Orçamento<br>
                    em 24h
                </span>
            </div>
        </div>
        <div class="col-3 d-none d-sm-block">
            <div class="escolha-menor">
                <span>
                    3.<br>
                    Escolha o<br>
                    melhor
                </span>
            </div>
        </div>

        <!-- MODILE -->
        <div class="col-7 d-block d-sm-none">
            <div class="row">
                <div class="col-12 mt-4 ">
                    <div class="faca-pedido-mobile">
                        <span>
                            1.<br>
                            Faça seu<br>
                            pedido
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="orcamento-24-mobile">
                        <span>
                            2.<br>
                            Orçamento<br>
                            em 24h
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="escolha-menor-mobile">
                        <span>
                            3.<br>
                            Escolha o<br>
                            melhor
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 d-block d-sm-none d-flex align-items-center justify-content-center">
            <img src="assets/images/logo-footer-big.png" alt="" class="side-image">
        </div>
        <!-- MOBILE -->

        <div class="col-12 col-md-4 offset-md-1 quem-somos-container mt-5">
            <p class="quem-somos d-flex justify-content-center justify-content-md-start">Quem<br>somos</p>
            <hr>
        </div>
        <div class="col-12 col-md-6 mt-5">
            <p class="description">O MKT 2P foi desenvolvido para atender todas as demandas na área da comunicação, criando um elo entre cliente,
                agência e freelancer, possibilitando que, em poucos cliques, o cliente possa solicitar um orçamento e obter em menos de 24h.
                3 diferentes orçamentos,  a agência consiga gerenciar e aumentar seu leque de clientes, e o freelancer possa conseguir
                o tão sonhado fluxo que gere certa estabilidade, sem ter que necessariamente ir atrás do cliente.
                É a ponte entre o necessitado e o provedor. É a união das pontas, por um valor acessível.</p>
        </div>
    </div>

    <div class="row partner">
        <div class="col-12">
            <h2>Seja um parceiro</h2>
            <hr>
            <p>Cadastre-se gratuitamente</p>
        </div>
    </div>
</div>

<app-contact-footer></app-contact-footer>
