import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.sass']
})
export class PedidosComponent implements OnInit {

  public clienteInfo = {
    id: 1234,
    name: 'Pedro da Silva',
    email: 'pedro@gmail.com',
    telefone: '11 98537-4540'
  }

  public PedidosCliente:pedidos[] = [
    {
      id: 1000,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    },
    {
      id: 1001,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    },
    {
      id: 1002,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    },
    {
      id: 1003,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    },
    {
      id: 1004,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    },
    {
      id: 1005,
      email: 'pedro@gmail.com',
      createDate: '25/09/2020',
      demanda: 'Logotipo Fábrica de Chocolate'
    }
  ]

  constructor() { }

  ngOnInit(): void {

  }

  refazerPedido(id) {
    console.log(id)
  }

}

export interface cliente{
  id: number;
  name: string;
  email: string;
  telefone: string;
}

export interface pedidos{
  id: number;
  email: string;
  createDate: string;
  demanda: string;
}
