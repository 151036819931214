import { Component, OnInit } from '@angular/core';
import { PlanosService } from './../shared/services/planos.service';
import { AuthenticationService } from './../shared/services/authentication.service';
import { User } from './../shared/_models/user';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.sass']
})
export class PlanosComponent implements OnInit {

  professionalType: number;
  tipoPlano: string;
  listaPlanos: Array<any>;
  currentUser: User;

  constructor(
    private planosService: PlanosService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x );
   }

  ngOnInit(): void {
    this.professionalType = 2;
    this.tipoPlano = 'FREE';

    this.planosService.fetchPlanos().subscribe(
      res => {
        this.listaPlanos = res.data;

        this.listaPlanos = res.data.filter(element => {
          return element.professionalType == 2;
        })
      },
      ({ error }) => {
        console.log(error)
      }
    );
  }

  alteraTipo(tipo) {
    this.listaPlanos = [];
    this.professionalType = tipo;

    this.planosService.fetchPlanos().subscribe(
      res => {
        this.listaPlanos = res.data;

        this.listaPlanos = res.data.filter(element => {
          if(tipo == 1){
            return element.professionalType == 1;
          }
          if(tipo == 2){
            return element.professionalType == 2;
          }
        })
      },
      ({ error }) => {
        console.log(error)
      }
    );
  }

  alteraTipoPlano(tipo, id) {
    this.tipoPlano = tipo;
    console.log(this.tipoPlano)
    console.log(id)
  }

}
