<div class="container-fluid bg-login">
  <div class="container container-login">
      <div class="row">
          <div class="col-12 col-md-4 offset-md-8">
              <div class="d-flex justify-content-center align-items-center">
                  <a href="/">
                    <img src="assets/images/logo-header.png" alt="" >
                  </a>
                  
              </div>
              <div class="mt-4">
                  <h5 class="text-center mb-3">Esqueci minha senha</h5>
                  <form [formGroup]="esqueciSenhaForm" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                          <input type="text" name="email" formControlName="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && esqueciSenhaForm.get('email').errors }">
                          <div *ngIf="submitted && esqueciSenhaForm.get('email').errors" class="invalid-feedback">
                            <div *ngIf="esqueciSenhaForm.get('email').errors.required">E-mail é obrigatório</div>
                          </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center mt-4">
                          <a [routerLink]="['/login']" class="btn btn-entrar mr-2">Voltar</a>
                          <button type="submit" class="btn btn-entrar">Enviar</button>
                      </div>
                      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                      <div *ngIf="sucess" class="alert alert-success mt-3 mb-0">Uma nova senha foi gerada e enviada para seu e-mail</div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
