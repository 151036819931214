<div class="container-fluid container-partner" [ngStyle]="{'padding-top': router.url == '/orcamento' ? '50px' : '235px' }">
    <div class="container">

        <div class="d-none d-sm-block" *ngIf="router.url.split('?')[0]  != '/orcamento' && router.url != '/planos'">
            <div class="float-container">
                <a href="/parceiro">
                    <div class="box-agencia">
                        <img src="assets/images/img-agencia.png" alt="">
                        <span>agência</span>
                    </div>
                </a>
                <a href="/parceiro">
                    <div class="box-freelancer">
                        <img src="assets/images/img-freelancer.png" alt="">
                        <span>freelancer</span>
                    </div>
                </a>
            </div>
        </div>

        <div class="d-block d-sm-none" *ngIf="!router.url.includes('/orcamento')">
            <div class="float-container-mobile">
                <a href="/parceiro">
                    <div class="box-agencia-mobile">
                        <img src="assets/images/img-agencia.png" alt="" class="img-fluid">
                        <span>agência</span>
                    </div>
                </a>
                <a href="/parceiro">
                    <div class="box-freelancer-mobile">
                        <img src="assets/images/img-freelancer.png" alt="" class="img-fluid">
                        <span>freelancer</span>
                    </div>
                </a>
            </div>
        </div>

        <div class="row contact">
            <div class="col-12 col-md-4 offset-md-2">
                <h3>Contato</h3>
                <hr>
                <p>Tem alguma dúvida? Podemos te ajudar pelo Nosso canal de e-mail.</p>

                <div class="d-none d-sm-block">
                    <div class="box-footer">
                        <img src="assets/images/logo-footer.png" alt="">
                        <div class="box-info">
                            <span>©2021 2PMarketing. All Rights Reserved.</span>
                            <div>
                                <a href="https://2pmkt.s3-sa-east-1.amazonaws.com/politicas-termos/termo-condicoes.pdf" target="_blank">Termos de uso</a>
                                <a href="#">Política de privacidade</a>
                                <a href="#">Cookies</a>
                            </div>
                        </div>
                    </div>
                </div>

                <img src="assets/images/logo-footer-big.png" alt="" class="logo-footer-big d-none d-sm-block">
            </div>
            <div class="col-12 col-md-4 contact-form">
                <form [formGroup]="contatoForm" novalidate>
                    <div class="form-group">
                        <label for="name">Seu nome</label>
                        <input type="text" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && contatoForm.get('name').errors }">
                        <div *ngIf="submitted && contatoForm.get('name').errors" class="invalid-feedback">
                          <div *ngIf="contatoForm.get('name').errors.required" style="color:#fff;">Nome é obrigatório</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Seu e-mail</label>
                        <input type="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && contatoForm.get('email').errors }">
                        <div *ngIf="submitted && contatoForm.get('email').errors" class="invalid-feedback">
                          <div *ngIf="contatoForm.get('email').errors.required" style="color:#fff;">E-mail é obrigatório</div>
                          <div *ngIf="contatoForm.get('email').errors?.email" style="color:#fff;">E-mail inválido</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message">Sua mensagem</label>
                        <textarea name="message" id="" cols="30" rows="10" formControlName="message" [ngClass]="{ 'is-invalid': submitted && contatoForm.get('message').errors }"></textarea>
                        <div *ngIf="submitted && contatoForm.get('message').errors" class="invalid-feedback">
                          <div *ngIf="contatoForm.get('message').errors.required" style="color:#fff;">Mensagem é obrigatório</div>
                        </div>
                    </div>

                    <div class="col-12 mb-4" *ngIf="showSuccess" style="color: #fff;">
                        {{message}}
                    </div>


                    <div class="control-button">
                        <button class="btn btn-enviar" (click)="onSubmit()">{{buttonText}}</button>
                    </div>
                </form>

                <div class="d-block d-sm-none">
                    <div class="box-footer-mobile">
                        <img src="assets/images/logo-footer.png" alt="">
                        <div class="box-info">
                            <span>©2021 2PMarketing. All Rights Reserved.</span>
                            <div>
                                <a href="#">Termos de uso</a>
                                <a href="#">Política de privacidade</a>
                                <a href="#">Cookies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
