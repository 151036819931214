import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // logged in so return true
            return true;
        }

        // if(this.router.url == '/' || this.router.url == '/orcamento' || this.router.url == '/parceiro' || this.router.url == '/cadastro' || this.router.url == '/login' || this.router.url == '/esqueci-senha'){
        //   return true;
        // }
        else{
          // not logged in so redirect to login page with the return url
          // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          console.log('nao logado')
          this.router.navigate(['']);
          return false;
        }

    }
}
