<div class="container-fluid">
    <div class="bg-topo"></div>
</div>

<div class="container">
    <div class="row seja-parceiro px-4 px-lg-0">
        <div class="col-12 col-md-6 mt-5">
            <h2>O serviço que<br>
              você precisa com<br>
              orçamentos<br>
              <span>gratuitos</span><br>
              em até em 24hs</h2>
            <hr class="mt-5 mb-3">
            <p>O que você precisa em 2P</p>
        </div>
        <div class="col-12 col-md-6">

          <div class="container-video">
            <iframe class="video" width="523" height="294" src="https://www.youtube.com/embed/ftf5ryt-cNk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

          <div class="d-flex flex-column flex-lg-row mt-3 cliente-destaque-topo">
            <div class="d-flex flex-column">
              <span class="number">1.</span>
              <span>Peça seu orçamento grátis</span>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-end px-4 mt-4 mt-lg-0">
              <!-- <i class="fa fa-chevron-right d-none d-sm-block"></i> -->
              <img src="assets/images/seta-esquerda.png" alt="" class="d-none d-sm-block">
            </div>
            <div class="d-flex flex-column">
              <span class="number">2.</span>
              <span>Receba 3 orçamentos em até 24hs</span>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-end px-4 mt-4 mt-lg-0">
              <!-- <i class="fa fa-chevron-right d-none d-sm-block"></i> -->
              <img src="assets/images/seta-esquerda.png" alt="" class="d-none d-sm-block">
            </div>
            <div class="d-flex flex-column">
              <span class="number">3.</span>
              <span>Selecione o melhor para você e faça o contato gratuito</span>
            </div>
          </div>
        </div>
    </div>
</div>

<div class="container-fluid servicos">
    <div class="d-flex justify-content-center">
        <div class="cadastro">
            <h2>Escolha o Serviço</h2>


            <div class="d-flex flex-column flex-lg-row mt-4">
              <div class="box-servicos mr-lg-3 mb-4 mb-lg-0">
                <div style="background: url(assets/images/servicos-marketing.png) no-repeat center center;background-size: cover;min-height:192px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
                <div class="text-center">
                  <p class="nome-servico mt-4">marketing digital<br>e redes sociais</p>
                  <a class="btn-solicita-orcamento cursor" (click)="solicitaOrcamento('Marketing Digital')">solicitar orçamento</a>
                </div>
              </div>

              <div class="box-servicos mr-lg-3 mb-4 mb-lg-0">
                <div style="background: url(assets/images/servicos-design.png) no-repeat center center;background-size: cover;min-height:192px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
                <div class="text-center">
                  <p class="nome-servico mt-4">design e<br>logotipos</p>
                  <a class="btn-solicita-orcamento cursor" (click)="solicitaOrcamento('Design')">solicitar orçamento</a>
                </div>
              </div>

              <div class="box-servicos mr-lg-3 mb-4 mb-lg-0">
                <div style="background: url(assets/images/servicos-site-loja.png) no-repeat center center;background-size: cover;min-height:192px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
                <div class="text-center">
                  <p class="nome-servico mt-4">site e<br>loja virtual</p>
                  <a class="btn-solicita-orcamento cursor" (click)="solicitaOrcamento('Web')">solicitar orçamento</a>
                </div>
              </div>

              <div class="box-servicos mr-lg-3 mb-4 mb-lg-0">
                <div style="background: url(assets/images/servicos-foto.png) no-repeat center center;background-size: cover;min-height:192px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
                <div class="text-center">
                  <p class="nome-servico mt-4">foto<br>&nbsp;</p>
                  <a class="btn-solicita-orcamento cursor" (click)="solicitaOrcamento('Fotografia')">solicitar orçamento</a>
                </div>
              </div>

              <div class="box-servicos">
                <div style="background: url(assets/images/servicos-filmagem.png) no-repeat center center;background-size: cover;min-height:192px;border-top-left-radius: 25px;border-top-right-radius: 25px;"></div>
                <div class="text-center">
                  <p class="nome-servico mt-4">filmagem<br>e edição</p>
                  <a class="btn-solicita-orcamento cursor" (click)="solicitaOrcamento('Vídeo')">solicitar orçamento</a>
                </div>
              </div>
            </div>

        </div>

    </div>
</div>

<div class="container-fluid footer-servicos">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div>
                    <h2>Avaliação de quem já contratou:</h2>

                    <div class="d-flex flex-column flex-lg-row justify-content-center mt-5">
                      <div class="box-avaliacao mr-lg-3 mb-4 mb-lg-0 d-flex flex-column">
                        <p class="mb-1">Evandro Almeida</p>
                        <span>Fui muito bem atendido. Agilidade no recebimento dos orçamentos fez toda diferença e o melhor, de graça.</span>
                        <div class="d-flex flex-row estrelas">
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="box-avaliacao mr-lg-3 mb-4 mb-lg-0 d-flex flex-column">
                        <p class="mb-1">Felipe Rossi</p>
                        <span>A 2P veio para preencher uma lacuna, e criar pontes para oportunidades inimagináveis, melhorando o fluxo detrabalho de nós, comunicadores!</span>
                        <div class="d-flex flex-row estrelas">
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="box-avaliacao mr-lg-3 mb-4 mb-lg-0 d-flex flex-column">
                        <p class="mb-1">Layse Melo</p>
                        <span>Achei super prático de usar. Recebi retorno de profissional qualificado, fui muito bem atendida e recomendo o uso da plataforma!</span>
                        <div class="d-flex flex-row estrelas">
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="box-avaliacao mb-4 mb-lg-0 d-flex flex-column">
                        <p class="mb-1">Ariane</p>
                        <span>Eu adorei a plataforma e todas as ferramentas que ela nos oferece, eu como fotógrafa posso me cadastrar e através dela conseguir jobs, acho que ela só veio para acrescentar eu estou apaixonada</span>
                        <div class="d-flex flex-row estrelas">
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star mr-2" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-10 offset-lg-1">
                <div class="d-flex align-items-center flex-column">
                    <hr class="my-5">

                    <div class="d-flex flex-column flex-lg-row">
                      <h3>Quem Somos</h3>
                      <p class="txt-quem-somos ml-lg-5">O MKT 2P foi desenvolvido para atender todas as demandas na área da comunicação, criando um elo entre cliente, agência e freelancer, possibilitando que, em poucos cliques, o cliente possa solicitar um orçamento e obter em menos de 24h. 3 diferentes orçamentos,  a agência consiga gerenciar e aumentar seu leque de clientes, e o freelancer possa conseguir o tão sonhado fluxo que gere certa estabilidade, sem ter que necessariamente ir atrás do cliente. É a ponte entre o necessitado e o provedor. É a união das pontas, por um valor acessível.</p>
                    </div>

                    <div class="info text-lg-right text-center align-items-center d-flex flex-column flex-lg-row justify-content-between mt-5">
                        <h4 class="mr-lg-5">seja um parceiro</h4>
                        <img src="assets/images/mini-logo-partner.png" alt="" class="mr-0 mr-lg-4">
                        <div class="mt-3 mt-lg-0">
                            <span class="mb-2">@2021 2P Marketing. All Rights Reserved.</span>
                            <a href="https://2pmkt.s3-sa-east-1.amazonaws.com/politicas-termos/termo-condicoes.pdf" target="_blank">Termos de Uso</a>
                            <a href="">Política de privacidade</a>
                            <a href="">Cookies</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
