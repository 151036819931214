<div class="container-fluid"
  *ngIf="router.url == '/parceiro'"
  style="background: url(/assets/images/bg-topo-partner.png) no-repeat -959px -1690px;
    position: absolute;
    height: 100%;
    background-size: 2285px;">

</div>
<div class="container" *ngIf="router.url != '/cadastro' && router.url != '/login' && router.url != '/esqueci-senha' && router.url != '/breve'">
  <nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" href="#">
      <img src="assets/images/logo-header.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" [ngStyle]="{ color : router.url == '/parceiro' ? '#ffffff' : '#000000)' }">
      <span class="navbar-toggler-icon">
        <i class="fa fa-bars"></i>
      </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav" *ngIf="router.url != '/parceiro' && router.url != '/cliente'">
        <li class="nav-item active">
          <a class="nav-link" href="/">home <span class="sr-only">(current)</span></a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="/orcamento">peça um orçamento</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">quem somos</a>
        </li> -->
        <li class="nav-item" *ngIf="!currentUser">
          <a class="nav-link" href="/parceiro">seja um parceiro</a>
        </li>
        <li class="nav-item" *ngIf="currentUser">
          <a class="nav-link" href="/planos">planos</a>
        </li>
        <li class="nav-item" *ngIf="currentUser">
          <a class="nav-item nav-link" [routerLink]="['/profissional/agencia']">minha conta</a>
        </li>
        <li class="nav-item" *ngIf="currentUser">
          <a class="nav-item nav-link" style="cursor:pointer" (click)="logout()">logout</a>
        </li>
      </ul>
    </div>

    <div class="control-buttons" *ngIf="!currentUser && router.url != '/cliente'">
      <a href="/cadastro" class="btn btn-cadastro">cadastre-se</a>
      <a href="/login" class="btn btn-entrar">entrar</a>
    </div>
    <div class="control-buttons" *ngIf="currentUser && router.url == '/parceiro' && router.url != '/cliente'">
      <a [routerLink]="['/profissional/agencia']" class="btn btn-entrar">entrar</a>
    </div>
  </nav>
</div>

