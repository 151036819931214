import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CountdownModule } from 'ngx-countdown';
import { CountdownGlobalConfig } from 'ngx-countdown';

import { JwtInterceptor } from './shared/_helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/_helpers/error.interceptor';

import { ClickOutsideDirective } from './shared/directives/click-outside.directive';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { HomeComponent } from './home/home.component';
import { OrcamentoComponent } from './orcamento/orcamento.component';
import { ContactFooterComponent } from './shared/component/contact-footer/contact-footer.component';
import { PartnerComponent } from './partner/partner.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { LoginComponent } from './login/login.component';
import { PedidosComponent } from './cliente/pedidos/pedidos.component';
import { AgenciaComponent } from './profissional/agencia/agencia.component';
import { PlanosComponent } from './planos/planos.component';
import { ConfirmaEmailComponent } from './email/confirmaEmail.component';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import { LgpdComponent } from './lgpd/lgpd.component';
import { AlterarCadastroComponent } from './alterar-cadastro/alterar-cadastro.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { AlterarServicosComponent } from './alterar-servicos/alterar-servicos.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreveComponent } from './breve/breve.component';
import { FooterComponent } from './shared/component/footer/footer.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    OrcamentoComponent,
    ContactFooterComponent,
    PartnerComponent,
    CadastroComponent,
    LoginComponent,
    PedidosComponent,
    AgenciaComponent,
    PlanosComponent,
    ConfirmaEmailComponent,
    EsqueciSenhaComponent,
    ClickOutsideDirective,
    LgpdComponent,
    AlterarCadastroComponent,
    AlterarSenhaComponent,
    AlterarServicosComponent,
    BreveComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    CountdownModule,
    NgbModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide:  DEFAULT_CURRENCY_CODE, useValue: ''}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
