export enum ProfessionalTypeEnum
{
    /// <summary>
    /// Freelance
    /// </summary>
    Freelancer = 1,

    /// <summary>
    /// Agência
    /// </summary>
    Agency = 2
}