import { Component, OnInit } from '@angular/core';
import { AlertService } from '../shared/services/alert.service';
import { CadastroService } from '../shared/services/cadastro.service';
import { ServiceTypeService } from '../shared/services/serviceType.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MustMatch } from '../shared/_helpers/must-match.validator';
import { ProfessionalTypeEnum } from '../shared/enums/professional-type.enum';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.sass']
})
export class CadastroComponent implements OnInit {

  showCadastroSuccess: boolean = false;
  etapa1: boolean = true;
  etapa2: boolean = false;
  etapa3: boolean = false;
  userTypeButton: number;
  cadastroForm = new FormGroup({});
  servicesList: Array<any>;
  submitted = false;
  isLoading: boolean = false;
  serviceTypesValidation: boolean = false;

  constructor(
    private cadastroService: CadastroService,
    private serviceTypeService: ServiceTypeService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {

    this.cadastroForm = this.formBuilder.group(
      {
        email: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        cellphone: new FormControl('', Validators.required),
        documentNumber: new FormControl('', Validators.required),
        documentType: new FormControl(''),
        professionalType: new FormControl('', Validators.required),
        serviceTypes: new FormArray([], Validators.required),
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirm: ['', Validators.required]
      }, {
      validator: MustMatch('password', 'passwordConfirm')
    }
    )

    this.serviceTypeService.fetchServices().subscribe(data => {
      this.servicesList = data.data;
    });

  }

  onCheckChange(event) {
    const formArray: FormArray = this.cadastroForm.get('serviceTypes') as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
    }
    else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  userType(userType) {
    if (userType == "agencia") {
      this.cadastroForm.controls.professionalType.setValue(ProfessionalTypeEnum.Agency);
      this.userTypeButton = 2;
    }
    if (userType == 'freelancer') {
      this.cadastroForm.controls.professionalType.setValue(ProfessionalTypeEnum.Freelancer);
      this.userTypeButton = 3;
    }

    this.cadastroForm.get('documentNumber').setValue(null);
    this.cadastroForm.get('documentNumber').setErrors(null);
  }

  jobList(jobList) {
    console.log(jobList);
    this.cadastroForm.controls.jobsList.setValue(jobList);
  }

  fechaCadastroSuccess() {
    // this.showCadastroSuccess = false;

    // Deve incluir essa linha apenas no GoLive do projeto para que o IIS redirecione para a tela correta
    // Assim que sair do GoLive, voltar com a linha de cima e descomentar o HTML
    window.location.href = 'https://www.2pmkt.com.br/';
  }

  stepBack1() {
    this.submitted = false;
    this.etapa1 = true;
    this.etapa2 = false;
  }
  stepBack2() {
    this.cadastroForm.controls.serviceTypes = new FormArray([], Validators.required);
    this.submitted = false;
    this.etapa2 = true;
    this.etapa3 = false;
  }

  step2() {
    console.log()
    this.submitted = true;

    if (this.cadastroForm.get('email').invalid || this.cadastroForm.get('professionalType').invalid) {
      return;
    }
    else {
      this.submitted = false;
    }

    this.etapa1 = false;
    this.etapa2 = true;
  }

  step3() {
    if (this.cadastroForm.get('serviceTypes').value.length == 0) {
      this.serviceTypesValidation = true;
      return;
    }
    else {
      this.submitted = false;
      this.serviceTypesValidation = false;
    }

    this.etapa1 = false;
    this.etapa2 = false;
    this.etapa3 = true;
  }

  onSubmit() {
    this.submitted = true;

    if (this.cadastroForm.invalid) {
      return;
    }

    if (this.cadastroForm.value.documentNumber.length == 11) {
      this.cadastroForm.value.documentType = 1;
    }
    if (this.cadastroForm.value.documentNumber.length == 14) {
      this.cadastroForm.value.documentType = 2;
    }
    
    this.isLoading = true;
    
    this.cadastroService.sendCadastroData({ ...this.cadastroForm.value }).subscribe(
      (res: any) => {
        console.log(res);
        this.showCadastroSuccess = true;
        this.isLoading = false;
      },
      (error: any) => {
        this.showCadastroSuccess = false;
        this.isLoading = false;
        this.alertService.error(error);
        console.log(error);
      }
    );
  }

}
