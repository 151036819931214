import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { ApiService } from './api.service';
import { User } from './../_models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(
      private http: HttpClient,
      private api: ApiService
    ) { }

    getAll() {
        return this.http.get<User[]>(`${environment.API_URL}/users`);
    }

    fetchUser() {
      const endpoint = 'Account/who-am-i';
      const route = environment.API_URL + endpoint;
      return this.api.get$(route);
    }
}
