import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlterarSenhaService } from './../shared/services/alterar-senha.service';
import { UserService } from './../shared/services/user.service';
import { User } from '../shared/_models/user';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.sass']
})
export class AlterarSenhaComponent implements OnInit {

  alterarSenhaForm: FormGroup;
  submitted = false;
  loading = false;
  message = '';
  buttonText = "Alterar";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alterarSenhaService: AlterarSenhaService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.userService.fetchUser().subscribe(res => {
      this.alterarSenhaForm.get('email').setValue(res.data.professional.email);
    })

    this.alterarSenhaForm = this.formBuilder.group({
      email: [''],
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  // conveniencia para acessar de forma mais fácil os form fields
  get f() { return this.alterarSenhaForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.buttonText = "Carregando...";

    // para se o form estiver inválido
    if (this.alterarSenhaForm.invalid) {
      this.buttonText = "Alterar";
        return;
    }

    this.loading = true;

    this.alterarSenhaService.sendAlterarSenhaData({ ...this.alterarSenhaForm.value }).subscribe(
      res => {
        this.message = res.message;
      },
      ({ error }) => {
        this.message = error;
        this.loading = false;
      }
    );

  }

}
