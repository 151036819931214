<div class="container-fluid" style="height: 70vh;">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center my-5">
                <h2 *ngIf="error" style="color: #8f8f8f;">{{error}}</h2>
                <h2 *ngIf="success" style="color: #8f8f8f;">Seu e-mail foi confirmado com sucesso.</h2>
                <button *ngIf="error" type="button" class="btn btn-primary mt-4" (click)="resendEmail()">
                    <i class="fa fa-envelope mr-3" aria-hidden="true"></i>
                    Reenviar E-mail
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
