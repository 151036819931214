import { Component, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { ServiceTypeService } from '../shared/services/serviceType.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  marketingdigital:  any = {};
  design:  any = {};
  web:  any = {};
  video:  any = {};
  fotografia:  any = {};
  serviceOptions: string;
  servicesList: Array<any>;
  serviceBox: boolean;
  submitted = false;
  Toggle =[];
  serviceIdPai: string;
  homeForm = new FormGroup({});
  serviceType: any = {};
  ActivateIndex: number[] =[];
  public text: String;

  constructor(
    private serviceTypeService: ServiceTypeService,
    private formBuilder: FormBuilder,
    private route: Router,
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.serviceTypeService.fetchServices().subscribe(data => {
      this.servicesList = data.data;
    });
    this.serviceType.show = false;

    this.homeForm = this.formBuilder.group(
      {
        services: new FormControl('', Validators.required),
      }
    )
  }

  someHandler() {
    this.serviceIdPai = '';
  }

  onItemChange(id) {
    this.serviceIdPai = id;
  }

  onSubmit() {
    this.submitted = true;

    if (this.homeForm.invalid) {
        return;
    }

    this.route.navigate(['/orcamento'], { queryParams: { service: this.homeForm.controls.services.value } });
  }

  toggle(index){
    console.log(index);

    if(this.ActivateIndex.includes(index)){
       this.ActivateIndex = this.ActivateIndex.filter(item =>item !== index);
    }
    else{
      this.ActivateIndex.push(index);
    }
  }

}
